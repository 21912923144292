import React from "react";
import * as XLSX from "xlsx";
import { formatDate } from "../common/SharedFunction";

interface ExcelExportProps {
  data: Array<object>;
  columns?: Array<string>; // Marking columns as optional
  fileName: string;
}

const ExcelExport: React.FC<ExcelExportProps> = ({
  data,
  columns = [],
  fileName,
}) => {
  const exportToExcel = () => {
    // If no columns are provided, auto-generate from the keys of the first object
    const columnHeaders =
      columns.length === 0 && data.length > 0 ? Object.keys(data[0]) : columns;

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(data, { header: columnHeaders });
    const attacheDate = formatDate(new Date()) ? formatDate(new Date()) :""
    // Create workbook and append the worksheet
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the file and prompt download
    XLSX.writeFile(workbook, `${fileName + attacheDate}.xlsx`);
  };

  return (
    <button onClick={exportToExcel}>
      <span className="text-white">
        <svg
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="currentColor"
        >
          <path d="m720-120 160-160-56-56-64 64v-167h-80v167l-64-64-56 56 160 160ZM560 0v-80h320V0H560ZM240-160q-33 0-56.5-23.5T160-240v-560q0-33 23.5-56.5T240-880h280l240 240v121h-80v-81H480v-200H240v560h240v80H240Zm0-80v-560 560Z" />
        </svg>
      </span>
    </button>
  );
};

export default ExcelExport;
