import React, { useEffect, useRef, useState } from "react";
import { TOnChangeInput } from "../../../../../helpers/AppType";

import ConfirmationModal from "../../../../../components/model/ConfirmationModal";

import CustomSearchDropdown from "../../../../../components/CustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../../../helpers/AppInterface";
import {
  createPriceList,
  fetchCityApiForPriceList,
  fetchCountryApiForPriceList,
  fetchPriceListApi,
  fetchStateApiForPriceList,
  handleDeletePriceList,
  IPriceListView,
  updatePriceList,
} from "./PriceListController";
import PriceListItemView from "./PriceListItemView";
import { formatDate } from "../../../../../common/SharedFunction";

interface IPropsPriceListView {
  isPriceListView: boolean;
  closePriceListView: () => void;
}
const PriceListView = ({
  isPriceListView,
  closePriceListView,
}: IPropsPriceListView) => {
  const [priceListLists, setPriceListList] = useState<IPriceListView[]>([]);
  const [priceListInput, setPriceListInputInput] = useState("");
  const [effectiveDateInput, setEffectiveDateInput] = useState("");
  // const productRefDropdown = useRef<HTMLButtonElement>(null);
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );
  const [priceListDropdown, setPriceListDropdown] = useState<any>(null);
  const [hasIdAvail, setHasIdAvail] = useState<number>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  // const [categoryList, setCategoryList] = useState<any>([]);
  // const [selectedCategory, setSelectedCategory] = useState<any>(false);
  const [selectedCountryId, setSelectedCountryId] = useState<any>(false);
  const [selectedStateId, setSelectedStateId] = useState<any>(false);
  const [selectedCityId, setSelectedCityId] = useState<any>(false);
  const [editSelectedCategoryId, setEditSelectedCategoryId] = useState("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editPriceListId, setEditPriceListId] = useState<number | undefined>(
    undefined
  );
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isPriceListItemShow, setIsPriceListItemShow] = useState(false);
  const [passDataInAddItem, setPassDataInAddItem] = useState<IPriceListView>();
  const [priceNameError, setPriceNameError] = useState("");
  const [effectiveDataInputError, setEffectiveDataInputError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");

  const handleCountryChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCountryId(selectedOption);
    setCountryError(selectedOption ? "" : "Country is required");
  };
  const handleStateChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedStateId(selectedOption);
    setStateError(selectedOption ? "" : "State is required");
  };
  const handleCityChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCityId(selectedOption);
    setCityError(selectedOption ? "" : "City is required");
  };
  const handelChange = (event: TOnChangeInput) => {
    // setPriceListInputInput(event.target.value);
    const value = event.target.value;
    setPriceListInputInput(value);
    setPriceNameError(value ? "" : "Price Name is required");
  };
  const handelChangeEffectiveDate = (event: TOnChangeInput) => {
    const value = event.target.value;
    setEffectiveDateInput(value);
    setEffectiveDataInputError(value ? "" : "Date is required");
  };

  const handelSubmit = () => {
    if (!priceListInput) setPriceNameError("Price Name is required");
    if (!selectedCountryId) setCountryError("Country is required");
    if (!selectedStateId) setStateError("State is required");
    if (!selectedCityId) setCityError("City is required");
    if (!effectiveDateInput)
      setEffectiveDataInputError("Date is required");
    if (effectiveDateInput && priceListInput) {
      if (isEditing && editPriceListId !== null) {
        updatePriceList(
          {
            price_list_name: priceListInput,
            country_id: selectedCountryId?.value,
            effective_from: effectiveDateInput,
            state_id: selectedStateId?.value,
            city_id: selectedCityId?.value,
          },
          setPriceListList,
          editPriceListId
        );
        setPriceListInputInput("");
        setSelectedCountryId(false);
        setSelectedStateId(false);
        setSelectedCityId(false);
        setEffectiveDateInput("");
        setIsEditing(false);
      } else {
        createPriceList(
          {
            price_list_name: priceListInput,
            country_id: selectedCountryId?.value,
            effective_from: effectiveDateInput,
            state_id: selectedStateId?.value,
            city_id: selectedCityId?.value,
          },
          setPriceListList
        );
        setPriceListInputInput("");
        setSelectedCountryId(false);
        setSelectedStateId(false);
        setSelectedCityId(false);
        setEffectiveDateInput("");
        setIsEditing(false);
      }
    }
    // setPriceListInputInput("");
    // setSelectedCountryId(false);
    // setSelectedStateId(false);
    // setSelectedCityId(false);
    // setEffectiveDateInput("");
    // setIsEditing(false);
  };
  const toggleDropdownPriceList = (PriceListId: number | undefined) => {
    const checkedId = priceListLists.find((abv) => abv.id === PriceListId);
    setHasIdAvail(checkedId?.id);
    setPriceListDropdown(!priceListDropdown);
  };

  // useEffect(() => {
  //   if (isPriceListView) {
  //     fetchPriceListApi(setPriceListList);
  //     fetchCountryApiForPriceList(setCountriesList);
  //     fetchStateApiForPriceList(setStateList, selectedCountryId.value);
  //     fetchCityApiForPriceList(setCityList, selectedStateId.value);
  //   }
  // }, [isPriceListView, selectedCountryId, selectedStateId]);
  useEffect(() => {
    if (isPriceListView) {
      const fetchData = async () => {
        try {
          await fetchPriceListApi(setPriceListList);
          await fetchCountryApiForPriceList(setCountriesList);
        } catch (error) {
          console.error("Error fetching price list or country options:", error);
        }
      };

      fetchData();
    }
  }, [isPriceListView]);

  useEffect(() => {
    if (selectedCountryId) {
      const fetchState = async () => {
        try {
          await fetchStateApiForPriceList(
            setStateList,
            selectedCountryId.value
          );
        } catch (error) {
          console.error("Error fetching city options:", error);
        }
      };
      fetchState();
    }
  }, [selectedCountryId]);
  useEffect(() => {
    if (selectedStateId) {
      const fetchCities = async () => {
        try {
          await fetchCityApiForPriceList(setCityList, selectedStateId.value);
        } catch (error) {
          console.error("Error fetching city options:", error);
        }
      };
      fetchCities();
    }
  }, [selectedStateId]);

  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setPriceListDropdown(null);
    }
  };

  useEffect(() => {
    if (priceListDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [priceListDropdown]);
  const countryOptions = countriesList.map((category: any) => ({
    value: category.id,
    label: category.country_name,
  }));
  const stateOptions = stateList.map((category: any) => ({
    value: category.id,
    label: category.state_name,
  }));
  const cityOptions = cityList.map((category: any) => ({
    value: category.id,
    label: category.city_name,
  }));

  const handleEdit = async (item: IPriceListView) => {
    setPriceListDropdown(null);
    setPriceListInputInput(item.price_list_name);
    setEffectiveDateInput(item.effective_from);
    setEditSelectedCategoryId(item.country_id);
    setIsEditing(true);
    setEditPriceListId(item.id);
    setSelectedCountryId({ value: item.country_id, label: item.country_name }); // Set the selected country
    setSelectedStateId({ value: item.state_id, label: item.state_name }); // Set the selected state
    setSelectedCityId({ value: item.city_id, label: item.city_name }); // Set th
  };
  const handelPriceListAddItem = (item: IPriceListView) => {
    setPassDataInAddItem(item);
    setIsPriceListItemShow(true);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // Set the max attribute to the current date
  const currentDate = getCurrentDate();

  return (
    <>
      {isPriceListView ? (
        <div
          className="notifications animate__animated animate__fadeInLeft"
          id="notifications"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <div
                aria-disabled="false"
                role="button"
                className="icons"
                data-tab="2"
                title="New chat"
                aria-label="New chat"
                onClick={closePriceListView}
              >
                <span data-testid="chat" data-icon="chat" className="">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="newText">
              <h2>Price list </h2>
            </div>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-notifications">
            {/* <!-- Chats 1 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="head" style={{ display: "block" }}>
                  {/* <div className="col-12 d-flex "> */}
                  <div className="col-12 d-flex justify-content-between w-100">
                    <div className="col-5 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Select Country</h4>
                      </label>
                      <div className="add-source-of-type-section ">
                        <CustomSearchDropdown
                          options={countryOptions}
                          value={selectedCountryId}
                          onChange={handleCountryChange}
                          className="w-100  "
                        />
                      </div>
                      {countryError && (
                        <span className="text-danger">{countryError}</span>
                      )}
                    </div>
                    <div className="col-5 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Select State</h4>
                      </label>
                      <div className="add-source-of-type-section ">
                        <CustomSearchDropdown
                          options={stateOptions}
                          value={selectedStateId}
                          onChange={handleStateChange}
                          className="w-100  "
                        />
                      </div>
                      {stateError && (
                        <span className="text-danger">{stateError}</span>
                      )}
                    </div>
                    {/* <div className="col-2"></div> */}
                  </div>
                  <div className="col-12 mt-2">
                    <div className=" w-100">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Select City</h4>
                      </label>
                      <div className="add-source-of-type-section ">
                        <CustomSearchDropdown
                          options={cityOptions}
                          value={selectedCityId}
                          onChange={handleCityChange}
                          className="w-100  "
                        />
                      </div>
                      {cityError && (
                        <span className="text-danger">{cityError}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 d-flex mt-2">
                    <div className="col-6 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Price List Name </h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          <input
                            type="text"
                            title="Price List Name"
                            placeholder="Price List Name"
                            value={priceListInput}
                            onChange={(e) => handelChange(e)}
                          />
                        </div>
                      </div>
                      {priceNameError && (
                        <span className="text-danger">{priceNameError}</span>
                      )}
                    </div>

                    <div className="col-5">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Effective Date</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section mx-2  d-flex">
                          <input
                            type="date"
                            value={effectiveDateInput}
                            onChange={handelChangeEffectiveDate}
                            className="form-control font-size-15 rounded-1"
                            min={currentDate}
                          />
                        </div>
                      </div>
                      {effectiveDataInputError && (
                        <span className="text-danger">{effectiveDataInputError}</span>
                      )}
                    </div>
                    <div className="col-1  d-flex  align-items-center ">
                      <button className="" onClick={handelSubmit}>
                        <span>
                          {isEditing ? (
                            <span>
                              <svg
                                data-name="Layer 1"
                                height={24}
                                id="Layer_1"
                                viewBox="0 0 200 200"
                              >
                                <title />
                                <path
                                  fill="currentColor"
                                  d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                                />
                              </svg>
                            </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="chats">
                  {priceListLists &&
                    priceListLists.map((item, index) => (
                      <>
                        <button
                          key={index}
                          className="block chat-list"
                          style={{ padding: "6" }}
                        >
                          <div className="h-text">
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Price List Name</b> :{item.price_list_name}
                              </h4>
                            </div>
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Effective Date </b> :
                                {item.effective_from
                                  ? formatDate(item.effective_from)
                                  : ""}
                              </h4>
                            </div>
                            {item.id === -1 ? (
                              <span></span>
                            ) : (
                              <>
                                <button
                                  className="icon-more float-end"
                                  onClick={() =>
                                    toggleDropdownPriceList(item.id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19 20"
                                    width="19"
                                    height="20"
                                    className="hide animate__animated animate__fadeInUp"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                    ></path>
                                  </svg>
                                </button>

                                <ul
                                  className={`price-list-option ${
                                    hasIdAvail === item.id && priceListDropdown
                                      ? "isVisible"
                                      : "isHidden"
                                  } `}
                                  id="dropLeft"
                                  ref={(el) =>
                                    (dropdownContactRef.current[item.id] = el)
                                  }
                                >
                                  <li
                                    className="listItem"
                                    role="button"
                                    onClick={() => handelPriceListAddItem(item)}
                                  >
                                    AddItem/View
                                  </li>
                                  <li
                                    className="listItem"
                                    role="button"
                                    onClick={() =>
                                      setIsDeleteConfirmation(true)
                                    }
                                  >
                                    Delete
                                  </li>
                                  <li
                                    className="listItem"
                                    role="button"
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </li>
                                </ul>
                              </>
                            )}
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Country Name</b> :
                                {item.country_name ? item.country_name : ""}
                              </h4>
                              {/* <p className="time"></p> */}
                            </div>

                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>State Name </b> :
                                {item.state_name ? item.state_name : ""}
                              </h4>
                            </div>
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>City Name </b>:
                                {item.city_name ? item.city_name : ""}
                              </h4>
                            </div>
                            {/* <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Net Rate </b>:
                                {item.net_rate ? item.net_rate : ""}
                              </h4>
                            </div> */}
                          </div>
                        </button>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeletePriceList(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setPriceListList
                )
              }
              title={"Delete this PriceList"}
              message={"Are You Sure You Want To Delete This PriceList?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </div>
      ) : null}
      {isPriceListItemShow && (
        <PriceListItemView
          show={isPriceListItemShow}
          onHide={() => setIsPriceListItemShow(false)}
          title={"Price List Item"}
          btn1={"Cancel"}
          btn2={"Save"} // headerName={"Price List Item"}
          passDataInAddItem={passDataInAddItem}
        />
      )}
    </>
  );
};

export default PriceListView;
