import { toast } from "react-toastify";
import { TReactSetState } from "../../../../../helpers/AppType";
import axiosInstance from "../../../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../helpers/AppConstants";
import { checkDuplication } from "../../../../../common/SharedFunction";

export interface IPriceListView {
  price_list_name: string;
  id: number;
  effective_from: string;
  created_date_time?: string;
  country_id: string;
  state_id: string;
  city_id: string;
  city_name : string
  state_name : string
  country_name :string
}
export interface IPriceListCreate {
  price_list_name: string;
  effective_from: string;
  created_date_time?: string;
  country_id: string;
  state_id: number;
  city_id: number;
}

export const handleDeletePriceList = async (
  priceListId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setPriceListList: TReactSetState<IPriceListView[]>
) => {
  const requestData = {
    table: "pricelist_masters",
    where: `{"id":${priceListId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchPriceListApi(setPriceListList);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const createPriceList = async (
  priceListInput: IPriceListCreate,
  setPriceListList: TReactSetState<IPriceListView[]>
) => {
  if (
    !(await checkDuplication(
      priceListInput.price_list_name,
      "pricelist_masters",
      "price_list_name"
    ))
  ) {
    const date = new Date();

      const formattedDateTime = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "pricelist_masters",
      data: JSON.stringify({
        price_list_name: priceListInput.price_list_name,
        effective_from: priceListInput.effective_from,
        country_id: priceListInput.country_id, // Include the HTML content here
        state_id: priceListInput.state_id,
        city_id: priceListInput.city_id,
        created_date_time: formattedDateTime,
        a_application_logins_id:Number(getUUID)
      }),
    };
    try {
      const { data } = await axiosInstance.post("commonCreate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          //   console.log("dadad", data);
          fetchPriceListApi(setPriceListList);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } else {
    toast.error("PriceList already available");
  }
};


export const fetchCountryApiForPriceList = async (setCountriesList: any) => {
  const requestData = {
    table: "a_countries",
    columns: "id,country_name,country_code",
    where: `{"isDelete": "0"}`,
  };

  try {
    const response = await axiosInstance.post("commonGet", requestData);

    setCountriesList(response.data.data); // Assuming API response is an array of countries
  } catch (error) {
    console.error("Error fetching countries:", error);
    // Handle error (e.g., show error message, clear filtered list)
    setCountriesList([]);
  }
};

export const fetchStateApiForPriceList = async (
  setStateList: any,
  selectedCountryId: any
) => {
  const requestData = {
    table: "a_states",
    columns: "id,state_name",
    where: `{"country_id": "${selectedCountryId}"}`,
  };

  try {
    const response = await axiosInstance.post("commonGet", requestData);

    setStateList(response.data.data); // Assuming API response is an array of countries
    // setStateList(response.data.data.map((state: any) => ({
    //   value: state.id,
    //   label: state.state_name,
    // })));

  } catch (error) {
    console.error("Error fetching countries:", error);
    // Handle error (e.g., show error message, clear filtered list)
    setStateList([]);
  }
};
export const fetchCityApiForPriceList = async (
  setCityList: TReactSetState<any>,
  selectedStateId: any
) => {
  const requestData = {
    table: "a_cities",
    columns: "id,city_name",
    where: `{"state_id": ${selectedStateId}}`,
  };

  try {
    const response = await axiosInstance.post("commonGet", requestData);

    setCityList(response.data.data); // Assuming API response is an array of countries
    // setCityList(response.data.data.map((state: any) => ({
    //   value: state.id,
    //   label: state.state_name,
    // })));
  } catch (error) {
    console.error("Error fetching countries:", error);
    // Handle error (e.g., show error message, clear filtered list)
    setCityList([]);
  }
};
export const fetchPriceListApi = async (
  setPriceListList: TReactSetState<IPriceListView[]>
) => {
  const token = await localStorage.getItem("token");

  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    // table: "products",
    // columns: "id,product_name,unit",
    // // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    // where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    // request_flag: 0,
    // order: `{"id":"DESC"}`,
    application_login_id: getUUID,
  };
  try {
    const data = await axiosInstance.post("priceListMaster", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setPriceListList([]);

      toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setPriceListList(data.data.data.item);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

// export const updatePriceList = async (
//   priceListInput: IPriceListCreate,
//   setPriceListList: TReactSetState<IPriceListView[]>,
//   editCategoryId: number | undefined
// ) => {
//   const getUUID = await localStorage.getItem("UUID");
//   const requestData = {
//     table: "products",
//     where: `{"id":"${editCategoryId}"}`,
//     data: `{"product_name":"${priceListInput.product_name}","unit":"${
//       priceListInput.unit
//     }","category_id":"${
//       priceListInput.category_id
//     }","a_application_login_id":${Number(getUUID)}}`,
//   };
//   try {
//     const { data } = await axiosInstance.post("commonUpdate", requestData);
//     if (data.code === 200) {
//       if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
//         setPriceListList((prevList) =>
//           prevList.map((category) =>
//             category.id === editCategoryId ? data.data : category
//           )
//         );
//         fetchPriceListApi(setPriceListList);
//       } else {
//         toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//       }
//     }
//   } catch (error: any) {
//     toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//   }
// };

export const updatePriceList = async (
  productInput: IPriceListCreate,
  setProductList: TReactSetState<IPriceListView[]>,
  editCategoryId: number | undefined,
) => {
  const requestData = {
    table: "pricelist_masters",
    where: `{"id":"${editCategoryId}"}`,
    data: JSON.stringify({
      price_list_name: productInput.price_list_name,
      effective_from: productInput.effective_from,
      country_id: productInput.country_id, // Include the HTML content here
      state_id: productInput.state_id,
      city_id: productInput.city_id,
    }),
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setProductList((prevList) =>
          prevList.map((category) =>
            category.id === editCategoryId ? data.data : category
          )
        );
        fetchPriceListApi(setProductList);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};