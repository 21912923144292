import { toast } from "react-toastify";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../helpers/AppConstants";
import { TReactSetState } from "../../helpers/AppType";
import axiosInstance from "../../services/axiosInstance";

export interface IUserList {
  id: number;
  person_name: string;
  mobile_number: string;
  company_name?: string;
  email_id?: string;
  country?: string;
  state?: string;
  district?: string;
  city?: string;
  area?: string;
  pincode?: string;
  address?: string;
  status?: number;
  country_name: string;
  state_name: string;
  city_name: string;
  created_date_time: string;
  source_name: string;
  source_name_color: string;
  lable:any
  label_color:string
  label_name : string
}

export interface ILoginData {
  username: string;
  // password: string,
  // recovery_email: string,
  recovery_mobile?: string;
  // created_date_time: Date,
  // gender: number,
  profile_pic: string;
  // s_timestemp: string,
  // login_pin: string,
}
export const fetchDataUser = async (
  page: number,
  term: string,
  setUsers: TReactSetState<IUserList[]>,
  itemsPerPage: number,
  setNoDataFound: TReactSetState<boolean>,
  setLoading: TReactSetState<boolean>,
  token: string | null,
  localId: string | null,
  setContactId:TReactSetState<number | undefined>,
  setSelectedLabelIds:TReactSetState<any>,
  filterData?:any,
  checkedOptionsLabel?:any,
  checkedSourceTypes?:any,
  startSearchDate?:string,
  endSearchDate?:string
) => {
  const start: number = page * itemsPerPage;
  // const commaSeparatedString = filterData?.join(',');
  // filterData.country , filterData.state , filterData.city , filterData.area
  try {
    const { data } = await axiosInstance.post(
      "Contact",
      {
        ul: start, // Upper limit based on page number
        ll: itemsPerPage, // Lower limit based on page number
        searchTerm: term || "",
        application_login_id: Number(localId),
        labelFilter:checkedOptionsLabel,
        sourceTypeFilter:checkedSourceTypes,
        country :filterData?.country,
      state : filterData?.state,
      city : filterData?.city,
      area :filterData?.area,
      startDate:startSearchDate,
      endDate:endSearchDate
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        if (page === 0) {
          setLoading(true);
          const transformedUserData = data.data.item.map((user: { lable: string; }) => ({
            ...user,
            lable: user.lable.split(',').filter(label => label !== '').map(Number)  
          }));
    
          setUsers(data.data.item);
          setContactId(data.data.item[0]?.id)
          setSelectedLabelIds(data.data.item[0]?.lable); 
        } else {
          setLoading(false);
          setUsers((prevUsers) => [...prevUsers, ...data.data.item]);
        }
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  } finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};


// export const fetchDataUserLabelFilter = async (
//   setUsers: TReactSetState<IUserList[]>,
//   setNoDataFound: TReactSetState<boolean>,
//   setLoading: TReactSetState<boolean>,
//   token: string | null,
//   localId: string | null,
//   filterData:any
// ) => {
// const commaSeparatedString = filterData.join(',');

//   try {
//     const { data } = await axiosInstance.post(
//       "Contact",
//       {
//         application_login_id: Number(localId),
//         labelFilter:commaSeparatedString
//       },
//       {
//         headers: {
//           Authorization: `${token}`,
//         },
//       }
//     );
//     if (data.code === 200) {
//       if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
//         // if (page === 0) {
//           setLoading(true);
//           setUsers(data.data.item);
//         // } else {
//         //   setLoading(false);
//         //   setUsers((prevUsers) => [...prevUsers, ...data.data.item]);
//         // }
//       } else {
//         toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//       }
//     }
//     setNoDataFound(data.data.item.length === 0);
//   } catch (error: any) {
//     toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//   } finally {
//     setTimeout(() => {
//       setLoading(false); // Set loading to false after minimum time
//     }, 1000); // 1000 milliseconds (1 seconds)
//   }
// };

export const fetchDataIndiaMart = async (setLoading : TReactSetState<boolean>) => {
  const getUUID = await localStorage.getItem("UUID");
  try {
    const { data } = await axiosInstance.post("India-mart",{
      a_application_login_id:getUUID,
      source_type_id:-1
    });
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        // if (page === 0) {
        //   setLoading(true);
        //   setUsers(data.data.item);
        // } else {
        //   setLoading(false);
        //   setUsers((prevUsers) => [...prevUsers, ...data.data.item]);
        // }
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    setLoading(true);
    // setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};


export const fetchGoogleSheetForFacebook = async (setLoading:TReactSetState<boolean>) => {
  const getUUID = await localStorage.getItem("UUID");
  try {
    const { data } = await axiosInstance.post("google-sheet-for-facebook",{
      a_application_login_id:getUUID,
      source_type_id:-2
    });
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        // if (page === 0) {
        //   setLoading(true);
        //   setUsers(data.data.item);
        // } else {
        //   setLoading(false);
        //   setUsers((prevUsers) => [...prevUsers, ...data.data.item]);
        // }
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    setLoading(true)
    // setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};

export const fetchWhatsAppApiWebhook = async (setLoading:TReactSetState<boolean>) => {
  try {
    const { data } = await axiosInstance.post("execute-webhook")
    setLoading(true);
  } catch (error: any) {
    setLoading(false);
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
};
export const fetchGetByIdUser = async (
  token: string | null,
  localId: string | null,
  setLoginById: TReactSetState<ILoginData | undefined>
) => {
  try {
    const { data } = await axiosInstance.post(
      "loginId",
      {
        loginId: localId,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setLoginById(data.data);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
    // setNoDataFound(data.data.item.length === 0);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
export const upateCheckBox = async (
  hasOneData: number | undefined,
  selectedOptions: any,
  setLoading:TReactSetState<boolean>
) => {
  const requestData = {
    table: "contact_masters",
    where: `{"id":"${hasOneData}"}`,
    data: `{"lable":"${selectedOptions}"}`,
  };
  setLoading(false)
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        // setIsDeleteConfirmation(false);
      setLoading(true)
      } else {
        setLoading(false)
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }finally {
    setTimeout(() => {
      setLoading(false); // Set loading to false after minimum time
    }, 1000); // 1000 milliseconds (1 seconds)
  }
  
  // Update profile detail or perform necessary actions
};


// export const upateCheckBox = async (
//   hasOneData: number | undefined,
//   selectedOptions: any
// ) => {
//   console.log("hasOneData:", hasOneData);
//   const requestData = {
//     table: "inquiries",
//     where: `{"id":"${hasOneData}"}`,
//     data: `{"label_id":"${selectedOptions}"}`,
//   };
//   try {
//     const { data } = await axiosInstance.post("commonUpdate", requestData);
//     if (data.code === 200) {
//       if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {

//       } else {
//         toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//       }
//     }
//   } catch (error: any) {
//     toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
//   }
//   // Update profile detail or perform necessary actions
// };
