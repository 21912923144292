import React, { useState } from "react";
import Security from "./Security";
import Wallpaper from "./Wallpaper";
import RequestAccountInfo from "./RequestAccountInfo";
import Help from "./Help";
import ConfirmationModal from "../../../../components/model/ConfirmationModal";
import ProfileSetting from "./ProfileSetting";
import Notifications from "./Notifications";
import Privacy from "./Privacy";
import { ILoginData } from "../../LeftSideController";
import SourceOfTypes from "./source-of-types/SourceOfTypes";
import LabelView from "./label/LabelView";
import CategoryView from "./category/CategoryView";
import ProductView from "./product/ProductView";
import PriceListView from "./priceList/PriceListView";
interface IPropsSetting {
  isSettingOpen: boolean;
  closeSettings: () => void;
  profileDetail?: ILoginData;
}

const Setting = ({
  isSettingOpen,
  closeSettings,
  profileDetail,
}: IPropsSetting) => {
  const [optionConfirmation, setOptionConfirmation] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showopenNotification, setShownNotification] = useState(false);
  const [showPrivacy, setShownPrivacy] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);
  const [showWallpaper, setShowWallpaper] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showopenSourceOfType, setshowopenSourceOfType] = useState(false);
  const [label, setLabel] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showPriceList, setShowPriceList] = useState(false);
  function openProfile() {
    setShowProfile(true);
  }
  function openNotifications() {
    setShownNotification(true);
  }
  function openPrivacy() {
    setShownPrivacy(true);
  }

  function openSecurity() {
    setShowSecurity(true);
  }

  function openWallpaper() {
    setShowWallpaper(true);
  }
  function openRequest() {
    setShowRequest(true);
  }
  function openHelp() {
    setShowHelp(true);
  }

  function openSourceOfType() {
    setshowopenSourceOfType(true);
  }
  function openLabel() {
    setLabel(true);
  }
  function openCategory() {
    setShowCategory(true);
  }
  function openProduct() {
    setShowProduct(true);
  }
  function openPriceList() {
    setShowPriceList(true);
  }
  return (
    <>
      {showProfile ||
      showopenNotification ||
      showPrivacy ||
      showSecurity ||
      showWallpaper ||
      showRequest ||
      showopenSourceOfType ||
      label ||
      showHelp ||
      showPriceList ||
      showCategory ||
      showProduct ? (
        <>
          <ProfileSetting
            isProfileOpen={showProfile}
            closeProfile={() => setShowProfile(false)}
            profileDetail={profileDetail}
          />
          <LabelView
            isLableView={label}
            closeLabelView={() => setLabel(false)}
          />
          <SourceOfTypes
            isSourceOfTypeOpen={showopenSourceOfType}
            closeSourceOfType={() => setshowopenSourceOfType(false)}
          />
          <Notifications
            isNotificationOpen={showopenNotification}
            closeNotifications={() => setShownNotification(false)}
          />
          <Privacy
            isPrivayOpen={showPrivacy}
            closePrivacy={() => setShownPrivacy(false)}
          />
          <Security
            isSecurityOpen={showSecurity}
            closeSecurity={() => setShowSecurity(false)}
          />
          <Wallpaper
            isWallpaperOpen={showWallpaper}
            closeWallpaper={() => setShowWallpaper(false)}
          />
          <RequestAccountInfo
            isRequestOpen={showRequest}
            closeRequest={() => setShowRequest(false)}
          />
          <Help isHelpOpen={showHelp} closeHelp={() => setShowHelp(false)} />
          <CategoryView
            isCategoryView={showCategory}
            closeCategoryView={() => setShowCategory(false)}
          />
          <ProductView
            isProductView={showProduct}
            closeProductView={() => setShowProduct(false)}
          />
          <PriceListView
            isPriceListView={showPriceList}
            closePriceListView={() => setShowPriceList(false)}
          />
        </>
      ) : (
        <>
          {isSettingOpen ? (
            <div
              className="settings animate__animated animate__fadeInLeft"
              id="settings"
            >
              {/* <!-- Header --> */}
              <div className="header-Chat">
                {/* <!-- Icons --> */}
                <div className="ICON">
                  <button className="icons" onClick={closeSettings}>
                    <span className="">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>

                <div className="newText">
                  <h2>Settings</h2>
                </div>
              </div>
              {/* <!-- Chats --> */}
              <div className="chats-settings">
                {/* <!-- Profile --> */}
                <div className="top" onClick={openProfile}>
                  {/* <!-- Img --> */}
                  <div className="imgBox">
                    {profileDetail?.profile_pic ? (
                      <img
                        src={`https://backend.smalloffice.in/${profileDetail?.profile_pic}`}
                        alt=""
                        className="cover"
                      />
                    ) : (
                      <img
                        src={require("../../../../assets/images/no_image.jpeg")}
                        alt=""
                        className="cover"
                      />
                    )}
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      {/* <h4>Abhishek Kumar Sharma</h4> */}
                      <h4>{profileDetail?.username}</h4>
                    </div>
                    <div className="message">
                      {/* <p>I`m a Front-End Developer / UI/UX Designer</p> */}
                      <p>{profileDetail?.recovery_mobile}</p>
                    </div>
                  </div>
                </div>
                {/* category */}
                <div className="block" onClick={openCategory}>
                  {/* <!-- Img --> */}
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        // data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="currentColor"
                        >
                          <path d="m260-520 220-360 220 360H260ZM700-80q-75 0-127.5-52.5T520-260q0-75 52.5-127.5T700-440q75 0 127.5 52.5T880-260q0 75-52.5 127.5T700-80Zm-580-20v-320h320v320H120Zm580-60q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-500-20h160v-160H200v160Zm202-420h156l-78-126-78 126Zm78 0ZM360-340Zm340 80Z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Category" aria-label="Category">
                        Category
                      </h4>
                    </div>
                  </div>
                </div>
                {/* category */}
                {/* Product */}
                <div className="block" onClick={openProduct}>
                  {/* <!-- Img --> */}
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        // data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          height="24px"
                          viewBox="0 -960 960 960"
                          width="24px"
                          fill="currentColor"
                        >
                          <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Product" aria-label="Product">
                        Product
                      </h4>
                    </div>
                  </div>
                </div>
                {/* Product */}
                {/* source of type */}
                <div className="block" onClick={openSourceOfType}>
                  {/* <!-- Img --> */}
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        // data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M12 21.7c.9 0 1.7-.8 1.7-1.7h-3.4c0 .9.8 1.7 1.7 1.7zm5.6-5.2v-4.7c0-2.7-1.8-4.8-4.3-5.4v-.6c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v.6c-2.5.6-4.3 2.7-4.3 5.4v4.7l-1.7 1.7v.9h14.6v-.9l-1.7-1.7z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Source Of Type" aria-label="Source Of Type">
                        Source Of Type
                      </h4>
                    </div>
                  </div>
                </div>
                {/* source of type */}
                {/* Label */}
                <div className="block" onClick={openLabel}>
                  {/* <!-- Img --> */}
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        // data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M12 21.7c.9 0 1.7-.8 1.7-1.7h-3.4c0 .9.8 1.7 1.7 1.7zm5.6-5.2v-4.7c0-2.7-1.8-4.8-4.3-5.4v-.6c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v.6c-2.5.6-4.3 2.7-4.3 5.4v4.7l-1.7 1.7v.9h14.6v-.9l-1.7-1.7z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Label" aria-label="Label">
                        Label
                      </h4>
                    </div>
                  </div>
                </div>
                {/* Label */}
                {/* Price List */}
                <div className="block" onClick={openPriceList}>
                  {/* <!-- Img --> */}
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        // data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          viewBox="0 0 1000 1004.1441"
                          height="24"
                          width="24"
                        >
                          <path d="m646 875.144h-521v-583h229c12 0 21-10 21-21v-229h417v562c0 12 9 21 21 21s20-9 20-21v-583c0-12-9-21-20-21h-459c-3 0-5 1-8 2-1 0-1 1-2 1-2 1-3 2-5 3l-250 250c0 1-1 2-2 3l-2 4c-1 2-2 5-2 8v625c0 11 10 21 21 21h542c11 0 21-10 21-21s-10-21-21-21zm-491-625 178-179v179zm762 635c0-47-51-63-84-69v-64c24 5 41 17 42 28s12 19 23 19c11-2 20-12 19-23-4-34-38-60-84-66v-23c0-11-9-20-20-20s-21 9-21 20v23c-48 6-84 35-84 71 0 48 51 63 84 70v63c-24-4-41-16-42-27-1-12-12-20-23-19s-20 11-18 23c3 33 37 60 83 66v22c0 12 9 21 21 21s20-9 20-21v-22c49-7 84-36 84-72zm-167-104c0-11 17-24 42-29v56c-34-8-42-19-42-27zm83 134v-57c34 8 42 19 42 27 0 12-17 25-42 30zm-562-540c-35 0-63 28-63 62s28 63 63 63 62-28 62-63-28-62-62-62zm0 83c-12 0-21-9-21-21s9-20 21-20 21 9 21 20-10 21-21 21zm0 84c-35 0-63 28-63 62s28 63 63 63 62-28 62-63-28-62-62-62zm0 83c-12 0-21-9-21-21s9-21 21-21 21 10 21 21-10 21-21 21zm0 83c-35 0-63 28-63 63s28 62 63 62 62-28 62-62-28-63-62-63zm0 84c-12 0-21-10-21-21s9-21 21-21 21 9 21 21-10 21-21 21zm167-375c-12 0-21 9-21 20s9 21 21 21h250c11 0 20-9 20-21s-9-20-20-20zm250 166h-250c-12 0-21 10-21 21s9 21 21 21h250c11 0 20-9 20-21s-9-21-20-21zm-42 167h-208c-12 0-21 9-21 21s9 21 21 21h208c11 0 21-10 21-21s-10-21-21-21z" />
                        </svg>
                      </span>
                    </button>
                  </div>
                  {/* <!-- Text --> */}
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Price List" aria-label="Price List">
                        Price List
                      </h4>
                    </div>
                  </div>
                </div>
                {/* Price List */}
                {/* <!-- Chats 1 --> */}
                {/* <div className="block" onClick={openNotifications}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span
                        data-testid="settings-notifications"
                        data-icon="settings-notifications"
                        className=""
                      >
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M12 21.7c.9 0 1.7-.8 1.7-1.7h-3.4c0 .9.8 1.7 1.7 1.7zm5.6-5.2v-4.7c0-2.7-1.8-4.8-4.3-5.4v-.6c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v.6c-2.5.6-4.3 2.7-4.3 5.4v4.7l-1.7 1.7v.9h14.6v-.9l-1.7-1.7z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Notifications" aria-label="Notifications">
                        Notifications
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 2 --> */}
                {/* <div className="block" onClick={openPrivacy}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          width="21"
                          height="21"
                          viewBox="0 0 28 35"
                          className=""
                        >
                          <path
                            d="M14 1.102a8.273 8.273 0 0 1 8.273 8.273l-.001 2.804.084.003A4.86 4.86 0 0 1 27 17.037v12.43a4.86 4.86 0 0 1-4.86 4.86H5.86A4.86 4.86 0 0 1 1 29.467v-12.43a4.86 4.86 0 0 1 4.727-4.858V9.375A8.273 8.273 0 0 1 14 1.102Zm0 18.458c-1.958 0-3.545 1.653-3.545 3.692s1.587 3.691 3.545 3.691 3.545-1.652 3.545-3.691c0-2.04-1.587-3.692-3.545-3.692Zm0-14.766c-2.538 0-4.61 2-4.722 4.51l-.005.217-.001 2.655h9.455V9.521A4.727 4.727 0 0 0 14 4.794Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Privacy" aria-label="Privacy">
                        Privacy
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 3 --> */}
                {/* <div className="block" onClick={openSecurity}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className=""
                        >
                          <path
                            d="M12.027 2 4 5.568v5.35c0 4.95 3.425 9.58 8.027 10.704 4.602-1.124 8.027-5.753 8.027-10.703V5.568L12.027 2Zm0 9.802h6.243c-.472 3.674-2.925 6.948-6.243 7.973v-7.964H5.784V6.727l6.243-2.774v7.849Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Security" aria-label="Security">
                        Security
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 4 --> */}
                {/* <div
                  className="block"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setOptionConfirmation(true)}
                >
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className=""
                        >
                          <path
                            d="m12 1 3.22 3.22h4.56v4.56L23 12l-3.22 3.22v4.56h-4.56L12 23l-3.22-3.22H4.22v-4.56L1 12l3.22-3.22V4.22h4.56L12 1Zm0 5v12c3.31 0 6-2.69 6-6a6.005 6.005 0 0 0-5.775-5.996L12 6Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Theme" aria-label="Theme">
                        Theme
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <div className="block" onClick={openWallpaper}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M4.9 5.9h6.4V4.1H4.9c-1 0-1.8.8-1.8 1.8v6.4h1.8V5.9zm5.3 8-3.6 4.4h10.7l-2.7-3.6-1.8 2.4-2.6-3.2zm6.2-4c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3.6 1.3 1.3 1.3 1.3-.6 1.3-1.3zm2.7-5.8h-6.4v1.8h6.4v6.4h1.8V5.9c0-1-.8-1.8-1.8-1.8zm0 16h-6.4v1.8h6.4c1 0 1.8-.8 1.8-1.8v-6.4h-1.8v6.4zM4.9 13.7H3.1v6.4c0 1 .8 1.8 1.8 1.8h6.4v-1.8H4.9v-6.4z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Chat wallpaper" aria-label="Chat wallpaper">
                        Chat wallpaper
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 6 --> */}
                {/* <div className="block" onClick={openRequest}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className=""
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6Zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1Zm-5 4a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8Zm6 5a1 1 0 0 0-1-1H8a1 1 0 1 0 0 2h5a1 1 0 0 0 1-1Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4
                        title="Request Account Info"
                        aria-label="Request Account Info"
                      >
                        Request Account Info
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 7 --> */}
                {/* <div className="block">
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M10.852 12.648h2.296L12 9ZM20 8.691V6c0-1.102-.898-2-2-2h-2.691L13.41 2.102a2 2 0 0 0-2.828 0L8.692 4H6c-1.102 0-2 .898-2 2v2.691L2.102 10.59a2.004 2.004 0 0 0 0 2.832L4 15.32V18c0 1.102.898 2 2 2h2.691l1.899 1.898c.781.782 2.05.782 2.832 0L15.32 20H18c1.102 0 2-.898 2-2v-2.691l1.898-1.899a2.004 2.004 0 0 0 0-2.832Zm-5.91 6.707L13.602 14h-3.204l-.488 1.398a.89.89 0 0 1-.84.602.888.888 0 0 1-.84-1.191l2.442-6.86C10.872 7.38 11.398 7 12 7c.602 0 1.129.379 1.34.941l2.441 6.86a.89.89 0 0 1-.84 1.187.868.868 0 0 1-.851-.59Zm0 0"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4
                        title="Keyboard shortcuts"
                        aria-label="Keyboard shortcuts"
                      >
                        Keyboard shortcuts
                      </h4>
                    </div>
                  </div>
                </div> */}

                {/* <!-- Chats 8 --> */}
                {/* <div className="block bottom" onClick={openHelp}>
                  <div className="icon-Box">
                    <button className="icons-setings">
                      <span className="">
                        <svg
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          className=""
                        >
                          <path
                            fill="currentColor"
                            d="M12 4.7c-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2 8.2-3.7 8.2-8.2-3.7-8.2-8.2-8.2zm.8 13.9h-1.6V17h1.6v1.6zm1.7-6.3-.7.7c-.7.6-1 1.1-1 2.3h-1.6v-.4c0-.9.3-1.7 1-2.3l1-1.1c.3-.2.5-.7.5-1.1 0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6H8.7c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 .8-.4 1.4-.8 1.9z"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div className="h-text">
                    <div className="head">
                      <h4 title="Help" aria-label="Help">
                        Help
                      </h4>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {optionConfirmation && (
        <ConfirmationModal
          show={optionConfirmation}
          onHide={() => setOptionConfirmation(false)}
          handleSubmit={() => "jhj"}
          title={"Choose theme"}
          btn1="CANCEL"
          btn2="OK"
          isoption={true}
          opt1={"Light"}
          opt2={"Dark"}
          opt3={"System default"}
        />
      )}
    </>
  );
};

export default Setting;
