import React, { useEffect, useState } from "react";
import "./ConfirmationModal.css";
import axiosInstance from "../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../helpers/AppConstants";
import { toast } from "react-toastify";
import { ISourceOfTypes } from "../../pages/left-side/header/Setting/source-of-types/SourceOfTypesController";
import { ILabelView } from "../../pages/left-side/header/Setting/label/LabelController";
import { SingleValue } from "react-select";
import { IOption } from "../../helpers/AppInterface";
import CustomSearchDropdown from "../CustomSearchDropdown";
interface ICheckBoxFilterInquiryModal {
  show: boolean;
  onHide: () => void;
  handleSubmit: (
    // filterData:any,
    checkedOptions1: any[],
    checkedOptionsSourceType: any[]
  ) => void;
  title: string;
  message: string;
  btn1: string;
  btn2: string;
}

const CheckBoxFilterInquiryModal: React.FC<ICheckBoxFilterInquiryModal> = ({
  show,
  onHide,
  handleSubmit,
  title,
  message,
  btn1,
  btn2,
}) => {
  const [checkedOptions, setCheckedOptions] = useState<any[]>();

  const [checkedOptionsSourceType, setCheckedOptionsSourceType] =
    useState<any>(); // State to manage selected checkboxes

  const [sourceOfTypesLists, setSourceOfTypesLists] = useState<
    ISourceOfTypes[]
  >([]);
  const [labelLists, setLabelList] = useState<ILabelView[]>([]);
  const [countriesList, setCountriesList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState<any>(false);
  const [selectedAreaId, setSelectedAreaId] = useState<any>(false);
  const [stateList, setStateList] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState<any>(false);
  const [cityList, setCityList] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState<any>(false);

  const onSubmit = async () => {
      
    const filterData = {
      country: selectedCountryId?.value, // Selected country ID
      state: selectedStateId?.value, // Selected state ID
      city: selectedCityId?.value, // Selected city ID
      area: selectedAreaId?.value, // Selected area ID
    };
    handleSubmit(
      // filterData, // Demography filter data
      checkedOptions ?? [], // Default to empty array if undefined
      checkedOptionsSourceType ?? [] // Default to empty array if undefined
    );
    
  };

  const handleHide = () => {
    // Clear all checkboxes
    // onHide(); // Call the original onHide function
    // setCheckedOptions([]);
    // if (checkedOptions || checkedOptionsSourceType) {
    //   setCheckedOptions([]);
    //   handleSubmit(checkedOptions ,checkedOptionsSourceType);
    // }
    setCheckedOptions([]); // Clear selected label IDs
    setCheckedOptionsSourceType([]); // Clear selected source type IDs
    
    // Reset demographic filter data
    setSelectedCountryId(null); // Clear selected country
    setSelectedStateId(null); // Clear selected state
    setSelectedCityId(null); // Clear selected city
    setSelectedAreaId(null);
    onHide();
    handleSubmit([],[])
  };
  const handleCategoryChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCountryId(selectedOption);
  };
  const handleAreaChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedAreaId(selectedOption);
  };
  const handleStateChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedStateId(selectedOption);
  };
  const handleCityChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCityId(selectedOption);
  };



  const fetchCountryApiForPriceList = async () => {
    const requestData = {
      table: "a_countries",
      columns: "id,country_name,country_code",
      where: `{"isDelete": "0"}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setCountriesList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setCountriesList([]);
    }
  };

  const fetchStateApiForPriceList = async () => {
    const requestData = {
      table: "a_states",
      columns: "id,state_name",
      where: `{"country_id": "${selectedCountryId.value}"}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);
      setStateList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setStateList([]);
    }
  };

  const fetchCityApiForPriceList = async () => {
    const requestData = {
      table: "a_cities",
      columns: "id,city_name",
      where: `{"state_id": ${selectedStateId.value}}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setCityList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      setCityList([]);
    }
  };
  const fetchAreaApiForFilter = async () => {
    const requestData = {
      table: "a_areas",
      columns: "id,area_name",
      where: `{"city_id": "${selectedCityId.value}"}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);
      setAreaList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setAreaList([]);
    }
  };
  const fetchLabelApi = async () => {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "lable_masters",
      columns: "id,lable_name,color",
      // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
      where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
      request_flag: 0,
      order: `{"id":"DESC"}`,
    };
    try {
      const data = await axiosInstance.post("commonGet", requestData);
      if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
        setLabelList([]);

        // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
      setLabelList(data.data.data);
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  };

  const handleCheckboxChange = (optionId: any) => {

    setCheckedOptions((prev) =>
      prev?.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...(prev || []), optionId]
    );
  };

  const handleSourceTypeCheckboxChange = (optionId: any) => {
    setCheckedOptionsSourceType((prev: any[]) =>
      prev?.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...(prev || []), optionId]
    );
  };




  const fetchSourceOfTypesApi = async () => {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "source_types",
      columns: "id,source_name,color",
      // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
      where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
      order: `{"id":"DESC"}`,
      request_flag: 0,
    };
    try {
      const data = await axiosInstance.post("commonGet", requestData);
      if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
        setSourceOfTypesLists([]);
        // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
      setSourceOfTypesLists(data.data.data);
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  };
  useEffect(() => {
    fetchSourceOfTypesApi();
    fetchLabelApi();
    // fetchCountryApiForPriceList();
  }, [show]);

  // useEffect(() => {
  //   if (selectedCountryId) {
  //     const fetchState = async () => {
  //       try {
  //         await fetchStateApiForPriceList();
  //       } catch (error) {
  //         console.error("Error fetching city options:", error);
  //       }
  //     };
  //     fetchState();
  //   }
  // }, [selectedCountryId]);
  // useEffect(() => {
  //   if (selectedStateId) {
  //     const fetchCities = async () => {
  //       try {
  //         await fetchCityApiForPriceList();
  //       } catch (error) {
  //         console.error("Error fetching city options:", error);
  //       }
  //     };
  //     fetchCities();
  //   }
  // }, [selectedStateId]);
  // useEffect(() => {
  //   if (selectedCityId) {
  //     const fetchCities = async () => {
  //       try {
  //         await fetchAreaApiForFilter();
  //       } catch (error) {
  //         console.error("Error fetching city options:", error);
  //       }
  //     };
  //     fetchCities();
  //   }
  // }, [selectedCityId]);

  // const countryOptions = countriesList.map((category: any) => ({
  //   value: category.id,
  //   label: category.country_name,
  // }));
  // const stateOptions = stateList.map((category: any) => ({
  //   value: category.id,
  //   label: category.state_name,
  // }));
  // const cityOptions = cityList.map((category: any) => ({
  //   value: category.id,
  //   label: category.city_name,
  // }));
  // const areaOptions = areaList.map((category: any) => ({
  //   value: category.id,
  //   label: category.area_name,
  // }));
  return (
    <div>
      {show && (
        <div className="modal1 ">
          <div className="modal-content1">
            <span className="close" onClick={onHide}>
              &times;
            </span>
            <h2 className="modal-title1 form_header_text">
              {" "}
              <h2>{title}</h2>
            </h2>
            <p className="text-center " style={{ color: "#999" }}>
              <p>{message}</p>
            </p>
            <div
              className={`m-title-2 col-12 d-flex justify-content-center`}
            >
               {/* <span className="ps-4">
               </span> */}
              <div className="col-5 card" >
                <div className="mx-1">
                <div className="ms-2 mt-1" >
                <label >Label </label>
                </div>
                <div
                  className="overflow-auto "
                  style={{ maxHeight: "500px"}}
                >
                  <table className="table table-hover" border={0}>
                    <tbody className="text-center">
                      {labelLists.map((option) => (
                        <tr
                          key={option.id}
                          className="text-left"
                          style={{
                            border: "1px solid white",
                            borderCollapse: "collapse",
                            height: "10px",
                          }}
                        >
                          <td className="text-start">
                            <span
                              style={{
                                backgroundColor: option.color
                                  ? option.color
                                  : "#808080",
                              }}
                              className="badge rounded-pill"
                            >
                              {option.lable_name}
                            </span>
                          </td>
                          <td className="text-end">
                            <input
                              type="checkbox"
                              id={`checkbox-${option.id}`}
                              checked={checkedOptions?.includes(option.id)}
                              onChange={() => handleCheckboxChange(option.id)}
                              className="custom-checkbox"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
              <div className="ps-3">
                </div>
              <div className="col-5 card " >
              <div className="mx-1">
                <div className="ms-2 mt-1">
                <label>Source type</label>
                </div>
                <div className="overflow-auto " style={{ maxHeight: "500px" }}>
                  <table className="table table-hover" border={0}>
                    <tbody className="text-center">
                      {sourceOfTypesLists.map((option) => (
                        <tr
                          key={option.id}
                          className="text-left"
                          style={{
                            border: "1px solid white",
                            borderCollapse: "collapse",
                            height: "10px",
                          }}
                        >
                          <td className="text-start">
                            <span
                              style={{
                                backgroundColor: option.color
                                  ? option.color
                                  : "#808080",
                              }}
                              className="badge rounded-pill"
                            >
                              {option.source_name}
                            </span>
                          </td>
                          <td className="text-end">
                            <input
                              type="checkbox"
                              id={`checkbox-${option.id}`}
                              checked={checkedOptionsSourceType?.includes(option.id)}
                              onChange={() => handleSourceTypeCheckboxChange(option.id)}
                              className="custom-checkbox"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              {/*  */}
            </div>
            <div className="modal-buttons">
              <button className="modal-button1" onClick={handleHide}>
                {btn1}
              </button>
              <button className="modal-button2" onClick={onSubmit}>
                {btn2}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckBoxFilterInquiryModal;
