import { toast } from "react-toastify";
import { DEFAULT_STATUS_CODE_SUCCESS, MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../../../../../helpers/AppConstants";
import { TReactSetState } from "../../../../../helpers/AppType";
import axiosInstance from "../../../../../services/axiosInstance";
export interface IPriceListItemCreate {
  pricelist_masters_id: number | undefined;
  product_id: string;
  rate: number;
  discount: number | undefined;
  net_rate: number;
  created_date_time?:string
  
}
export interface IPriceListItemView {
  pricelist_masters_id: string;
  product_id: string;
  rate: string;
  discount: number;
  net_rate: number;
  created_date_time:string
  id:number
  product_name:string
  gst_number:number
}

export const handleDeletePriceListItem = async (
  priceListId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setPriceListList: TReactSetState<IPriceListItemView[]>,
  pricelist_masters_id:any
) => {
  const requestData = {
    table: "price_lists",
    where: `{"id":${priceListId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchPriceListItemApi(setPriceListList,pricelist_masters_id);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
export const fetchProductApiForPriceListItem = async (
  setProductList: TReactSetState<any>,
  // setGstFromProduct:TReactSetState<any>,
  // setNetRateFromProduct:TReactSetState<any>
) => {
  const token = await localStorage.getItem("token");

  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    // table: "products",
    // columns: "id,product_name,unit",
    // // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    // where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    // request_flag: 0,
    // order: `{"id":"DESC"}`,
    application_login_id: getUUID,
  };
  try {
    const data = await axiosInstance.post("product", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setProductList([]);

      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    // const product = data.data.data.item; // Assuming you're fetching one product for simplicity
    // setNetRateFromProduct(product.net_rate);
    // setGstFromProduct(product.GST);
    setProductList(data.data.data.item);

  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};


export const createPriceListItem = async (
  priceListInput: IPriceListItemCreate,
  setPriceListItem: TReactSetState<IPriceListItemView[]>,
  pricelist_masters_id:any
  
) => {
  // if (
  //   !(await checkDuplication(
  //     priceListInput.price_list_name,
  //     "pricelist_masters",
  //     "price_list_name"
  //   ))
  // ) {
    const date = new Date();

      const formattedDateTime = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "price_lists",
      data: JSON.stringify({
        pricelist_masters_id: priceListInput.pricelist_masters_id,
        product_id: priceListInput.product_id,
        rate: priceListInput.rate, // Include the HTML content here
        discount: priceListInput.discount,
        net_rate: priceListInput.net_rate,
        created_date_time: formattedDateTime,
        // a_application_logins_id:Number(getUUID)
      }),
    };
    try {
      const { data } = await axiosInstance.post("commonCreate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          //   console.log("dadad", data);
          fetchPriceListItemApi(setPriceListItem,pricelist_masters_id);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  // } else {
  //   toast.error("PriceList already available");
  // }
};

export const fetchPriceListItemApi = async (
  setPriceListList: TReactSetState<IPriceListItemView[]>,
  pricelist_masters_id:any
) => {
  const token = await localStorage.getItem("token");

  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    // table: "products",
    // columns: "id,product_name,unit",
    // // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    // where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    // request_flag: 0,
    // order: `{"id":"DESC"}`,
    // application_login_id: getUUID,
    pricelist_masters_id : pricelist_masters_id
  };
  try {
    const data = await axiosInstance.post("priceListItem", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setPriceListList([]);

      toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setPriceListList(data.data.data.item);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const updatePriceListItem = async (
  productInput: IPriceListItemCreate,
  setProductList: TReactSetState<IPriceListItemView[]>,
  editCategoryId: number | undefined,
  pricelist_masters_id:number | undefined
) => {
  const requestData = {
    table: "price_lists",
    where: `{"id":"${editCategoryId}"}`,
    data: JSON.stringify({
      // pricelist_masters_id: productInput.pricelist_masters_id,
      product_id: productInput.product_id,
      rate: productInput.rate, // Include the HTML content here
      discount: productInput.discount,
      net_rate: productInput.net_rate,
      // a_application_logins_id:Number(getUUID)
    }),
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setProductList((prevList) =>
          prevList.map((category) =>
            category.id === editCategoryId ? data.data : category
          )
        );
        fetchPriceListItemApi(setProductList,pricelist_masters_id);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
