import React from "react";
import LeftSideView from "../pages/left-side/LeftSideView";
import Index from "../pages/public/Index";
import { AppProvider } from "../common/AppContext";
const RoutesIndex = () => {
  let isGroupOpen;
  return (
    <>
      {/* <LeftSideView isVisible={!isGroupOpen} /> */}
      {/* <LeftSideView  /> */}
      <AppProvider>

          <Index />
          </AppProvider>
    </>
  );
};
export default RoutesIndex;
