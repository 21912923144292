import * as Yup from "yup";
import axiosInstance from "../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../helpers/AppConstants";
import { toast } from "react-toastify";
import { TReactSetState } from "../../../helpers/AppType";
import { checkDuplication } from "../../../common/SharedFunction";

export interface ICreateCustomer {
  email_id: string;
  person_name: string;
  mobile_number: number | string;
  country: string;
  state: string;
  district: string;
  city: string;
  area: string;
  pincode: number | string;
  address: string;
  source_type_id: string;
  //   doa: Date | string;
  //   gender: string;
  //   last_name: string;
  //   dob: Date | string;
  //   id_branch: number | string;
  //   id_firm: number | string;
}

export const createCustomerInitialValues = (
  contactData: ICreateCustomer | undefined
): ICreateCustomer => ({
  person_name: contactData?.person_name || "",
  //   last_name: "",
  email_id: contactData?.email_id || "",
  mobile_number: contactData?.mobile_number || "",
  country: contactData?.country || "101",
  state: contactData?.state || "",
  district: contactData?.district || "",
  city: contactData?.city || "",
  area: contactData?.area || "",
  pincode: contactData?.pincode || "",
  address: contactData?.address || "",
  source_type_id: contactData?.source_type_id || "",
});

export const createCustomerValidationSchema = () =>
  Yup.object().shape({
    person_name: Yup.string()
      // .max(70, t("validationEmailMax"))
      // .email(t("validationEmail"))
      .required("Name is Required"),
    mobile_number: Yup.string()
      // .matches(ContactNumberRegex, t("validationContactNumberMatch"))
      .max(15, "Max mobile number 15")
      .min(10, "Min mobile number 10")
      .required("Mobile Number Required"),
    // country: Yup.string().required("Country is Required"),
    // state: Yup.string().required("State is Required"),
    email_id: Yup.string().email("Please write proper email "),
    pincode: Yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'PinCode should not contain special characters'),
    // state : Yup.string()
    // .required("state Number Required"),
  });

export const createContact = async (
  values: ICreateCustomer,
  setContact: any
) => {
  if (
    !(await checkDuplication(
      values.mobile_number,
      "contact_masters",
      "mobile_number"
    ))
  ) {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = { ...values, a_application_login_id: getUUID };

    try {
      setContact(false);

      const { data } = await axiosInstance.post("createContact", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          // console.log("dadad", data);

          setContact(true);
          // console.log("data", data);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } else {
    toast.error("Contact number is already used");
  }
};

export const updateContact = async (
  values: ICreateCustomer,
  setContact: any,
  contactId: number | string | undefined,
  setIsCreateContact1:TReactSetState<boolean>,
  closeChatAbout:any
) => {
  setIsCreateContact1(false)
  const requestData = {
    table: "contact_masters",
    where: `{"id":${contactId}}`,
    data: `{"person_name":"${values.person_name}","email_id":"${values.email_id}", "mobile_number":"${values.mobile_number}", "country":"${values.country}" , "state" : "${values.state}" , "district":"${values.district}" , "city" :"${values.city}" ,"area": " ${values.area}" , "pincode" :"${values.pincode}","address":"${values.address}","source_type_id":"${values.source_type_id}"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);

    if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
      setContact(true);
      setIsCreateContact1(true)
      closeChatAbout()
    } else {
      toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
