import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import {
  createCompany,
  createCompanyInitialValues,
  createCompanyValidationSchema,
  updateCompany,
} from "./CreateCompanyController";

const CreateCompanyView = ({ show, onHide, companyToEdit, setRefresh  , headerName}: any) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default behavior for Enter key
      }
    };

    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  const handleSubmit = async (values: any) => {
    if (companyToEdit?.id) {
      updateCompany( values, setRefresh , companyToEdit?.id , onHide);
    } else {
    createCompany(values, setRefresh , onHide);
    }
    // onHide(); // Example: Close modal after submission using onHide prop
  };

  return (
    <React.Fragment>
      {show && (
        <div className="modal1 ">
          <div className="modal-content1">
            <span className="close" onClick={onHide}>
              &times;
            </span>
            <h2 className="modal-title1 form_header_text">{headerName}</h2>
            <p className="text-center " style={{ color: "#999" }}>
              {" "}
              Please enter your Company detail.
            </p>
            <Formik
              enableReinitialize
              initialValues={createCompanyInitialValues(companyToEdit)}
              validationSchema={createCompanyValidationSchema()}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="  mt-3    d-flex justify-content-center">
                    <div className="mb-3 py-4  ">
                      <div className="row  mx-0 px-2 gy-3  d-flex justify-content-center">
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="company_name"
                              className="pb-2 form_label"
                            >
                              Company Name
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              name="company_name"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.company_name &&
                                touched.company_name &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="company_name"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="company_email"
                              className="pb-2 form_label"
                            >
                              Email
                            </label>
                            <Field
                              type="email"
                              name="company_email"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.company_email &&
                                touched.company_email &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="company_email"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="company_contact"
                              className="pb-2 form_label"
                            >
                              Mobile Number
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              type="text"
                              name="company_contact"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.company_contact &&
                                touched.company_contact &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="company_contact"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-6"></div>
                        <div className="col-12 ">
                          <div className="form-group">
                            <label
                              htmlFor="india_mart_api_key"
                              className="pb-2 form_label"
                            >
                              India Mart Apikey
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="india_mart_api_key"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.india_mart_api_key &&
                                touched.india_mart_api_key &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="india_mart_api_key"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        {/* <div className="col-4">
                          <p>Trade india integration</p>
                        </div>
                        <div className="col-8"></div> */}
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="trade_india_user_id"
                              className="pb-2 form_label"
                            >
                              Trade India Api User Id
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="trade_india_user_id"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.trade_india_user_id &&
                                touched.trade_india_user_id &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="trade_india_user_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="trade_india_profile_id"
                              className="pb-2 form_label"
                            >
                              Trade India Api Profile Id
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                             as="textarea"
                              name="trade_india_profile_id"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.trade_india_profile_id &&
                                touched.trade_india_profile_id &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="trade_india_profile_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 ">
                          <div className="form-group">
                            <label
                              htmlFor="trade_india_key"
                              className="pb-2 form_label"
                            >
                              Trade India ApiKey
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="trade_india_key"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.trade_india_key &&
                                touched.trade_india_key &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="trade_india_key"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="whatsapp_authkey"
                              className="pb-2 form_label"
                            >
                             Whatsapp Api authkey
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                             as="textarea"
                              name="whatsapp_authkey"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.whatsapp_authkey &&
                                touched.whatsapp_authkey &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="whatsapp_authkey"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="whatsapp_appkey"
                              className="pb-2 form_label"
                            >
                             Whatsapp Api AppKey
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="whatsapp_appkey"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.whatsapp_appkey &&
                                touched.whatsapp_appkey &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="whatsapp_appkey"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        {/* // google sheet */}
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="google_lead_sheet_for_faceBook_1"
                              className="pb-2 form_label"
                            >
                             Google Lead Sheet For FaceBook 1
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="google_lead_sheet_for_faceBook_1"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.google_lead_sheet_for_faceBook_1 &&
                                touched.google_lead_sheet_for_faceBook_1 &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="google_lead_sheet_for_faceBook_1"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="google_lead_sheet_for_faceBook_2"
                              className="pb-2 form_label"
                            >
                             Google Lead Sheet For FaceBook 2
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="google_lead_sheet_for_faceBook_2"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.google_lead_sheet_for_faceBook_2 &&
                                touched.google_lead_sheet_for_faceBook_2 &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="google_lead_sheet_for_faceBook_2"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="google_sheet_key_3"
                              className="pb-2 form_label"
                            >
                             Google sheet Key 3
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="google_sheet_key_3"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.google_sheet_key_3 &&
                                touched.google_sheet_key_3 &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="google_sheet_key_3"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="google_sheet_key_4"
                              className="pb-2 form_label"
                            >
                             Google sheet Key 4
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <Field
                              as="textarea"
                              name="google_sheet_key_4"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.google_sheet_key_4 &&
                                touched.google_sheet_key_4 &&
                                "is-invalid input-box-error"
                              }`}
                              rows={1}
                            />
                            <ErrorMessage
                              name="google_sheet_key_4"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-12 pt-4 d-flex justify-content-center">
                          {/* px-4 me-2 py-2 */}
                          <button
                            className="border border-1 bg-danger px-4 me-2 py-2 text-light rounded-1 form_label"
                            // onClick={() => compareValues(values)}
                            onClick={onHide}
                          >
                            Close
                          </button>
                          {/* px-4 py-2 ms-2 */}
                          <button
                            type="submit"
                            className="btn btn-primary px-4 py-2 ms-2  text-light form_label rounded-1"
                            // disabled={isSubmitting}
                            style={{
                              backgroundColor: "#f58634",
                            }}
                          >
                            Save Company
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateCompanyView;
