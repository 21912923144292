import { toast } from "react-toastify";
import { TReactSetState } from "../../../../../helpers/AppType";
import axiosInstance from "../../../../../services/axiosInstance";
import {
  DEFAULT_STATUS_CODE_SUCCESS,
  MESSAGE_UNKNOWN_ERROR_OCCURRED,
} from "../../../../../helpers/AppConstants";
import { checkDuplication } from "../../../../../common/SharedFunction";

export interface IProductView {
  product_name: string;
  id: number;
  created_date_time?: string;
  category_id: string;
  unit: string;
  category_name?: string;
  rate: string;
  net_rate: string;
  GST: string;
}
export interface IProductCreate {
  product_name: string;
  created_date_time?: string;
  category_id: string;
  unit: string;
  category_name?: string;
  rate: string;
  net_rate: string;
  GST: string;
}

export const handleDeleteProduct = async (
  productId: number | undefined,
  setIsDeleteConfirmation: TReactSetState<boolean>,
  setProductList: TReactSetState<IProductView[]>
) => {
  const requestData = {
    table: "products",
    where: `{"id":${productId}}`,
    data: `{"isDelete":"1"}`,
  };
  try {
    const data = await axiosInstance.post("commonUpdate", requestData);
    if (data.data.code === 200) {
      if (data.data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setIsDeleteConfirmation(false);
        fetchProductApi(setProductList);
      } else {
        toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const createProduct = async (
  productInput: IProductCreate,
  setProductList: TReactSetState<IProductView[]>
) => {
  if (
    !(await checkDuplication(
      productInput.product_name,
      "products",
      "product_name"
    ))
  ) {
    const getUUID = await localStorage.getItem("UUID");
    const requestData = {
      table: "products",
      data: `{"product_name":"${productInput.product_name}","unit":"${
        productInput.unit
      }","category_id":"${productInput.category_id}","rate":"${
        productInput.rate
      }","GST":"${productInput.GST}","net_rate":"${
        productInput.net_rate
      }","a_application_login_id":${Number(getUUID)}}`,
    };
    try {
      const { data } = await axiosInstance.post("commonCreate", requestData);
      if (data.code === 200) {
        if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
          //   console.log("dadad", data);
          fetchProductApi(setProductList);
        } else {
          toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
        }
      }
    } catch (error: any) {
      toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
  } else {
    toast.error("Product already available");
  }
};

export const fetchCategoryApiForProduct = async (
  setCategoryList: TReactSetState<[]>
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "categories",
    columns: "id,category_name",
    // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    request_flag: 0,
    order: `{"id":"DESC"}`,
  };
  try {
    const response = await axiosInstance.post("commonGet", requestData);

    setCategoryList(response.data.data); // Assuming API response is an array of countries
  } catch (error) {
    console.error("Error fetching countries:", error);
    // Handle error (e.g., show error message, clear filtered list)
    setCategoryList([]);
  }
};

export const fetchProductApi = async (
  setProductList: TReactSetState<IProductView[]>
) => {
  const token = await localStorage.getItem("token");

  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    // table: "products",
    // columns: "id,product_name,unit",
    // // where: `{"isDelete":"0","a_application_login_id":"${getUUID}"}`,
    // where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
    // request_flag: 0,
    // order: `{"id":"DESC"}`,
    application_login_id: getUUID,
  };
  try {
    const data = await axiosInstance.post("product", requestData, {
      headers: {
        Authorization: `${token}`,
      },
    });
    if (data.data.ack !== DEFAULT_STATUS_CODE_SUCCESS) {
      setProductList([]);

      // toast.error(data.data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
    }
    setProductList(data.data.data.item);
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};

export const updateProduct = async (
  productInput: IProductCreate,
  setProductList: TReactSetState<IProductView[]>,
  editCategoryId: number | undefined
) => {
  const getUUID = await localStorage.getItem("UUID");
  const requestData = {
    table: "products",
    where: `{"id":"${editCategoryId}"}`,
    data: `{"product_name":"${productInput.product_name}","unit":"${
      productInput.unit
    }","category_id":"${productInput.category_id}","rate":"${
      productInput.rate
    }","GST":"${productInput.GST}","net_rate":"${
      productInput.net_rate
    }","a_application_login_id":${Number(getUUID)}}`,
  };
  try {
    const { data } = await axiosInstance.post("commonUpdate", requestData);
    if (data.code === 200) {
      if (data.ack === DEFAULT_STATUS_CODE_SUCCESS) {
        setProductList((prevList) =>
          prevList.map((category) =>
            category.id === editCategoryId ? data.data : category
          )
        );
        fetchProductApi(setProductList);
      } else {
        toast.error(data.ack_msg || MESSAGE_UNKNOWN_ERROR_OCCURRED);
      }
    }
  } catch (error: any) {
    toast.error(error || MESSAGE_UNKNOWN_ERROR_OCCURRED);
  }
};
