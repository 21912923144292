import React, { useState, useEffect, useRef } from "react";
import { EditorState, RichUtils, DraftHandleValue, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import { toast } from "react-toastify";
import { MESSAGE_UNKNOWN_ERROR_OCCURRED } from "../helpers/AppConstants";
interface ICustomEditorProps {
  fieldName: string;
  text: string;
  onChange: (fieldName: string, html: string) => void; // Callback function for content changes
  onSend: (html: string) => void; // Callback function for sending content
  isToggledButton: boolean;
  handleChangeToggleButton: any;
  contactData: any;
  setIsLoadedMessage: any;
}

const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const createEditorState = (text: string): EditorState => {
  const contentState = stateFromHTML(text);
  return EditorState.createWithContent(contentState);
};

const CustomEditor: React.FC<ICustomEditorProps> = ({
  fieldName,
  text,
  onChange,
  onSend,
  isToggledButton,
  handleChangeToggleButton,
  contactData,
  setIsLoadedMessage,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    setEditorState(createEditorState(text || ""));
  }, [text]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const html = stateToHTML(editorState.getCurrentContent());

    // Notify parent about content changes
    onChange(fieldName, html);
  };

  const handleSend = () => {
    const html = stateToHTML(editorState.getCurrentContent());

    const strippedHtml = html.replace(/<[^>]+>/g, "").trim();

    if (strippedHtml) {
      // Only send if the stripped HTML content is not empty
      onSend(html);
    } else {
      console.log("Empty content, not sending.");
    }

    setEditorState(EditorState.createEmpty());
  };
  const handleKeyCommand = (
    command: string,
    editorState: EditorState
  ): DraftHandleValue => {
    if (command === "stop-enter") {
      return "handled";
    }
    return RichUtils.handleKeyCommand(
      editorState,
      command
    ) as unknown as DraftHandleValue;
  };
  const handleReturn = (event: React.KeyboardEvent): boolean => {
    if (event.shiftKey) {
      // Allow new line when Shift + Enter is pressed
      return false; // Returning false allows the default behavior (creating a new line)
    }
    handleSend();
    // Prevent any action when only Enter is pressed
    return true; // Returning true stops the default behavior
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoadedMessage(false);

    const getUUID = await localStorage.getItem("UUID");

    const file = event.target.files && event.target.files[0];
    console.log("File MIME Type:", file?.type);

    if (file) {
      // const validTypes = ["image/png", "image/jpeg", "application/pdf"];
      const validTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword", // .doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/zip", // .zip
        "image/svg+xml",
        "video/mp4",
        "video/x-matroska", // .mkv
        "video/mpeg",
        "text/plain",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls
        "application/vnd.ms-powerpoint", // .ppt
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
        "text/csv", // .csv
        // Uncomment the following if you want to include RAR support
        // "application/x-rar-compressed", // .rar
        // "application/vnd.rar",          // fallback for .rar
        // "application/octet-stream",     // fallback for generic binary files
        // "image/vnd.adobe.photoshop", // .psd
        "application/xml",
        "text/xml",
      ];
      const fileType = file.type;
      console.log("fileType", fileType);

      const fileExtension = file.name.split(",").pop()?.toLocaleLowerCase();

      if (!getUUID || !file) {
        return;
      }

      if (validTypes.includes(fileType)) {
        if (file.size <= MAX_FILE_SIZE_BYTES) {
          setSelectedFile(file);
          const formData = new FormData();
          formData.append("file", file);
          formData.append("contact_masters_id", contactData.id);
          formData.append("a_application_id", getUUID);
          formData.append("message_type_id", "1");

          try {
            const response = await axios.post(
              "https://backend.smalloffice.in/api/messageAttachmentsUpload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data", // Set content type for FormData
                },
              }
            );
            // window.location.reload();

            if (response.status === 200) {
              // Handle success
              setIsLoadedMessage(true);

              toast.success("Your attachment is added successfully");
            } else {
              // Handle other status codes or errors
              toast.error("An unexpected error occurred.");

              console.error("Failed to change profile image");
            }
          } catch (error: any) {
            // Handle network errors or other exceptions
            if (error.response && error.response.data) {
              toast.error(error.response.data.message); // Show error message from server
            } else {
              toast.error("An unexpected error occurred.");
            }
          }
        } else {
          toast.error(
            `File is too large. Please upload a file smaller than ${MAX_FILE_SIZE_MB} MB.`
          );

          // Optionally, you can clear the input value to allow re-selection
          event.target.value = "";
        }
        // setSelectedFile(file);
      } else {
        toast.error(
          "Invalid file format. Please upload a jpeg, jpg, png, pdf, docx, zip, svg, mp4, mkv, mpeg, txt, xlsx, xls, ppt, pptx, csv, xml"
        );

        // Optionally, you can clear the input value to allow re-selection
        event.target.value = "";
      }
    }
  };
  return (
    <div className="w-100 h-100  border" ref={wrapperRef}>
      <Editor
        // placeholder={fieldName}
        editorState={editorState}
        toolbar={{
          options: [
            "inline",
            // "blockType",
            "list",
            // "link",
            // "image",
            // "colorPicker",
          ],
        }}
        onEditorStateChange={onEditorStateChange}
        handleReturn={handleReturn}
      />
      <div className="bg-light col-12 d-flex ">
        <div className="col-6 d-flex align-items-center">
          <div className="chat-attach">
            <button className="icons Marg" id="chat-popup">
              <div className="form-group1">
                <label htmlFor="input-files">
                  <span data-testid="camera" data-icon="camera" className="">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className=""
                    >
                      <path
                        fill="currentColor"
                        d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                      ></path>
                    </svg>
                  </span>
                </label>
                <input
                  type="file"
                  name="image"
                  id="input-files"
                  className="form-control-file border"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                  // accept=".png,.jpg,.jpeg,.pdf"
                  // accept=".rar"
                />
              </div>
              {/* <span className="">
                <svg viewBox="0 0 24 24" width="24" height="24" className="">
                  <path
                    fill="currentColor"
                    d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"
                  ></path>
                </svg>
              </span> */}
            </button>
          </div>
          <div className="">
            <button
              onClick={handleChangeToggleButton}
              className={`toggle-button ${isToggledButton ? "on" : "off"}`}
            >
              {isToggledButton ? "CL" : "ME"}
            </button>
          </div>
        </div>
        {/* <button  onClick={handleSend}>Send</button> */}
        <div className="col-6 d-flex justify-content-end">
          <button className="send_box_icons" onClick={handleSend}>
            <span className="">
              <svg
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="currentColor"
              >
                <path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
              </svg>
            </span>
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default CustomEditor;
