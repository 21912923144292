import React, { useEffect, useState } from "react";

import {
  createPriceListItem,
  fetchPriceListItemApi,
  fetchProductApiForPriceListItem,
  handleDeletePriceListItem,
  IPriceListItemView,
  updatePriceListItem,
  // updateCompany,
} from "./PriceListItemController";

import { IPriceListView } from "./PriceListController";
import CustomSearchDropdown from "../../../../../components/CustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../../../helpers/AppInterface";
import { TOnChangeInput } from "../../../../../helpers/AppType";
import ConfirmationModal from "../../../../../components/model/ConfirmationModal";
// import { useTheme } from "../ThemeContext";

const PriceListItemView = ({
  show,
  onHide,
  // handleSubmit,
  title,
  btn1 = "yes",
  btn2 = "no",
  passDataInAddItem,
}: {
  show: boolean;
  onHide: () => void;
  // handleSubmit: () => void;
  title: string;
  btn1: string;
  btn2: string;
  passDataInAddItem: IPriceListView | undefined;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default behavior for Enter key
      }
    };

    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  const [productList, setProductList] = useState<any>([]);
  const [gstFromProduct, setGstFromProduct] = useState<any>();
  const [netRateFromProduct, setNetRateFromProduct] = useState<any>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [selectedProductId, setSelectProductId] = useState<any>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [rateInput, setRateInput] = useState<number>(0);
  const [discountInput, setDiscountInput] = useState<number>(0);
  const [netRateInput, setNetRateInput] = useState<number>(0);
  const [netRateInput1, setNetRateInput1] = useState<number>();
  const [priceListLists, setPriceListItem] = useState<IPriceListItemView[]>([]);
  const [editPriceListItemId, setEditPriceListItemId] = useState<
    number | undefined
  >(undefined);
  const handleSubmit = async (values: any) => {
    // if (companyToEdit?.id) {
    //   updateCompany( values, setRefresh , companyToEdit?.id , onHide);
    // } else {
    // createCompany(values, setRefresh , onHide);
    // }
    // onHide(); // Example: Close modal after submission using onHide prop
  };

  console.log("netRateFromProduct", netRateFromProduct, gstFromProduct);

  useEffect(() => {
    // if (isProductView) {
    fetchProductApiForPriceListItem(setProductList);
    fetchPriceListItemApi(setPriceListItem, passDataInAddItem?.id);
    // }
  }, [passDataInAddItem?.id]);
  const productOptions = productList.map((category: any) => ({
    value: category.id,
    label: category.product_name,
    netRate: category.rate,
    gst: category.GST,
  }));
  const calculateNetRate = (rate: number, discount: number, gst: number) => {
    // Step 2: DISCOUNT_AMOUNT = (rate * discount) / 100
    const discount_amount = (rate * discount) / 100;
    // Step 3: discounted_amount = rate - discount_amount
    const discounted_amount = rate - discount_amount;
    // Step 1: gst_amount = (gst * discounted_amount) / 100
    const gst_amount = (gst * discounted_amount) / 100;
    // Step 4: final = gst_amount + discounted_amount
    const final = gst_amount + discounted_amount;

    // Set the final value as the net rate
    // setNetRateInput(final)

    setNetRateInput(final);
  };

  const handleProductChange = (selectedOption: SingleValue<any>) => {
    setSelectProductId(selectedOption);
    setNetRateFromProduct(selectedOption.netRate);
    // setGstFromProduct(selectedOption.gst);
    const gst = selectedOption?.gst || 0; // Assuming `gst` is in percentage
    setGstFromProduct(gst);
    const netrate = selectedOption?.netRate || 0;
    setNetRateFromProduct(netrate);
    calculateNetRate(netrate, discountInput, gst);
  };
  const handelSubmit = () => {
    if (netRateFromProduct) {
      if (isEditing && editPriceListItemId !== null) {
        updatePriceListItem(
          {
            pricelist_masters_id: passDataInAddItem?.id,
            product_id: selectedProductId?.value,
            rate: netRateFromProduct,
            discount: discountInput,
            net_rate: netRateInput,
          },
          setPriceListItem,
          editPriceListItemId,
          passDataInAddItem?.id
        );
      } else {
        createPriceListItem(
          {
            pricelist_masters_id: passDataInAddItem?.id,
            product_id: selectedProductId?.value,
            rate: netRateFromProduct,
            discount: discountInput,
            net_rate: netRateInput,
          },
          setPriceListItem,
          passDataInAddItem?.id
        );
      }
    }
    setRateInput(0);
    setDiscountInput(0);
    setNetRateInput(0);
    setGstFromProduct("");
    setNetRateFromProduct("");
    setIsEditing(false);
    setSelectProductId("");
  };

  const handelChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rate = parseFloat(event.target.value) || 0;
    setRateInput(rate);
    calculateNetRate(rate, discountInput, gstFromProduct);
  };

  const handelChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const discount = parseFloat(event.target.value);
    setDiscountInput(discount);
    calculateNetRate(netRateFromProduct, discount, gstFromProduct);
  };

  const handelChangeNetRate = (event: TOnChangeInput) => {
    setNetRateInput(parseFloat(event.target.value));
  };
  const handleDeleteById = (id: number) => {
    setNetRateInput1(id);
    setIsDeleteConfirmation(true);
  };
  const handleEdit = (item: IPriceListItemView) => {
    const selectedProductOption = productOptions.find(
      (option: { value: string }) => option.value === item.product_id
    );

    setDiscountInput(item.discount);
    setNetRateFromProduct(item.rate);
    setNetRateInput(item.net_rate);
    setGstFromProduct(item.gst_number);

    // setSelectedCategory(selectedCategoryOption);
    setSelectProductId(selectedProductOption);
    setIsEditing(true);
    setEditPriceListItemId(item.id);
  };
  return (
    <React.Fragment>
      {show && (
        <div className="modal1 ">
          <div className="modal-content1" style={{ maxHeight: "80%" }}>
            <span className="close" onClick={onHide}>
              &times;
            </span>
            <h2 className="modal-title1 form_header_text">
              {" "}
              Product List Name : {passDataInAddItem?.price_list_name}
            </h2>
            <p className="text-center " style={{ color: "#999" }}>
              {" "}
              Please enter your Price List Item detail.
            </p>
            <div
              className={`m-title-2 col-12 `}
              // style={{ backgroundColor: "#e2e4e6" }}
            >
              <div className="head">
                <div className="source-of-type-list-grid-block">
                  <div className="source-of-type-list-grid-main">
                    <table className="table table-hover" border={0}>
                      <thead>
                        <th >
                          <b>Product</b>
                        </th>
                        <th>
                          <b>Rate</b>
                        </th>
                        <th>
                          <b>Discount (%)</b>
                        </th>
                        <th>
                          <b>GST</b>
                        </th>
                        <th>
                          <b>Net Rate</b>
                        </th>
                        {/* <td className=""></td> */}
                      </thead>
                      <tbody className="">
                        {/* {priceListLists &&
                          priceListLists.map((item, index) => ( */}
                        <tr
                          // key={index}
                          className=""
                          style={
                            {
                              // border: "1px solid white",
                              // borderCollapse: "collapse",
                              // height: "10px",
                            }
                          }
                        >
                          {/*<td className="text-start" width={"14%"}>
                            <p>{passDataInAddItem?.price_list_name}</p>
                          </td> */}
                          <td className="text-start" width={"20%"}>
                            <div className="w-100">
                              <CustomSearchDropdown
                                options={productOptions}
                                value={selectedProductId}
                                onChange={handleProductChange}
                                className="w-100"
                              />
                            </div>
                            {/* <span>
                                  {" "}
                                  {item.effective_from
                                    ? formatDate(item.effective_from)
                                    : ""}{" "}
                                </span> */}
                          </td>
                          <td className="text-start">
                            <div className="search-bar ">
                              <div className="add-source-of-type-section ">
                                <input
                                  type="text"
                                  title="Rate"
                                  placeholder="Rate"
                                  value={netRateFromProduct}
                                  // onChange={(e) => handelChangeRate(e)}
                                  readOnly
                                  // style={{backgroundColor:"#f0f2f5"}}
                                />
                              </div>
                            </div>

                            {/* <span>{item.country_name}</span> */}
                          </td>
                          <td className="text-start">
                            <div className="search-bar ">
                              <div className="add-source-of-type-section ">
                                <input
                                  // type="text"
                                  // title="Discount"
                                  // placeholder="Discount"
                                  // value={discountInput}
                                  // onChange={(e) => handelChangeDiscount(e)}
                                  // style={{ backgroundColor: "#f0f2f5" }}
                                  type="number" // Changed to number to accept decimals
                                  title="Discount"
                                  placeholder="Discount"
                                  value={discountInput}
                                  onChange={(e) => handelChangeDiscount(e)}
                                  style={{ backgroundColor: "#f0f2f5" }}
                                  step="0.01" // This allows decimal points
                                  min="0" // Optional: restricts negative values if needed
                                />
                                {/* <label
                                  style={{
                                    backgroundColor: "#f0f2f5",
                                    border: "1px solid #f0f2f5",
                                    padding: "5px",
                                  }}
                                >
                                  %
                                </label> */}
                              </div>
                            </div>
                            {/* <span>{item.state_name}</span> */}
                          </td>
                          <td className="text-start">
                            <div className="search-bar mt-3">
                              <div className="add-source-of-type-section ">
                                {gstFromProduct ? gstFromProduct : ""} %
                              </div>
                            </div>
                          </td>
                          <td className="text-start">
                            <div className="search-bar ">
                              <div className="add-source-of-type-section ">
                                <input
                                  type="text"
                                  title="Net rate"
                                  placeholder="Net rate"
                                  value={netRateInput.toFixed(2)}
                                  // onChange={(e) => handelChangeNetRate(e)}
                                  readOnly
                                  style={{ backgroundColor: "#f0f2f5" }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className=" mt-2">
                              <button className="" onClick={handelSubmit}>
                                <span>
                                  {isEditing ? (
                                    <span>
                                      <svg
                                        data-name="Layer 1"
                                        height={24}
                                        id="Layer_1"
                                        viewBox="0 0 200 200"
                                      >
                                        <title />
                                        <path
                                          fill="currentColor"
                                          d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                                        />
                                      </svg>
                                    </span>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="26px"
                                      viewBox="0 -960 960 960"
                                      width="26px"
                                      fill="#5f6368"
                                      // className="ml-2"
                                    >
                                      <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                    </svg>
                                  )}
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                        {/* ))} */}
                        {/* ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="source-of-type-list-grid-block">
                  <div className="source-of-type-list-grid-main">
                    <table className="table table-hover" border={0}>
                      <thead>
                        {/* <th>
                          <b> Name</b>
                        </th> */}
                        <th className="text-center">
                          <b>Product</b>
                        </th>
                        <th className="text-center">
                          <b>Rate</b>
                        </th>
                        <th className="text-center">
                          <b>Discount (%)</b>
                        </th>
                        <th className="text-center">
                          <b>GST (%)</b>
                        </th>
                        <th className="text-center">
                          <b>Net Rate</b>
                        </th>
                        <th className="text-center">
                          <b>Action</b>
                        </th>
                        {/* <td className=""></td> */}
                      </thead>
                      <tbody className="text-center">
                        {priceListLists &&
                          priceListLists.map((item, index) => (
                            <tr
                              key={index}
                              className=""
                              style={
                                {
                                  // border: "1px solid white",
                                  // borderCollapse: "collapse",
                                  // height: "10px",
                                }
                              }
                            >
                              {/* <td className="text-start">
                            <span>{item.price_list_name}</span>
                          </td> */}

                              <td className="text-start">
                                <span>{item.product_name}</span>
                              </td>
                              <td className="text-end">
                                <span>{item.rate}</span>
                              </td>
                              <td className="text-end">
                                <span>{item.discount}</span>
                              </td>
                              <td className="text-end">
                                <span>
                                  {item.gst_number ? item.gst_number : ""}
                                </span>
                              </td>
                              <td className="text-end">
                                <span>{item.net_rate}</span>
                              </td>
                              <td className="text-center">
                                <span
                                  data-testid="pencil"
                                  data-icon="pencil"
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEdit(item)}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                                    ></path>
                                  </svg>
                                </span>
                                |
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteById(item.id)}
                                >
                                  <svg
                                    // height="24px"
                                    viewBox="0 -960 960 960"
                                    width="22px"
                                    fill="currentColor"
                                  >
                                    <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                                  </svg>
                                </span>
                              </td>
                              {/* <td className="text-start">
                                {item.id === -1 ? (
                                  <span></span>
                                ) : (
                                  <>
                                    <button
                                      className="source-of-type-list-grid-options"
                                      id="source-of-types-options-id"
                                      onClick={() =>
                                        toggleDropdownPriceList(item.id)
                                      }
                                      // ref={productRefDropdown}
                                    >
                                      <span>
                                        <svg
                                          viewBox="0 0 24 24"
                                          width="24"
                                          height="24"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                                          ></path>
                                        </svg>
                                      </span>
                                    </button>
                                    <ul
                                      className={`source-of-types-options ${
                                        hasIdAvail === item.id &&
                                        priceListDropdown
                                          ? "isVisible"
                                          : "isHidden"
                                      } `}
                                      id="dropLeft"
                                      ref={(el) =>
                                        (dropdownContactRef.current[item.id] =
                                          el)
                                      }
                                    >
                                      <li
                                        className="listItem"
                                        role="button"
                                        onClick={() =>
                                         
                                          handelPriceListAddItem(item)
                                        }
                                      >
                                        AddItem/View
                                      </li>
                                      <li
                                        className="listItem"
                                        role="button"
                                        onClick={() =>
                                          setIsDeleteConfirmation(true)
                                        }
                                      >
                                        Delete
                                      </li>
                                      <li
                                        className="listItem"
                                        role="button"
                                        onClick={() => handleEdit(item)}
                                      >
                                        Edit
                                      </li>
                                    </ul>
                                  </>
                                )}
                              </td> */}
                            </tr>
                          ))}
                        {/* ))} */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeletePriceListItem(
                  netRateInput1,
                  setIsDeleteConfirmation,
                  setPriceListItem,
                  passDataInAddItem?.id
                )
              }
              title={"Delete this PriceList"}
              message={"Are You Sure You Want To Delete This PriceList?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PriceListItemView;
