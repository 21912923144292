import React, { useContext, useEffect, useState } from "react";
import { IUserList } from "../left-side/LeftSideController";
import CreateContactView from "../left-side/create-contact/CreateContactView";
import { AppContext } from "../../common/AppContext";
interface IPropsProfile {
  isProfile: boolean;
  closeChatAbout: () => void;
  getInfo?: IUserList;
  deleteContact: () => void;
  setIsCreateContact1:any
}

const Profile = ({
  isProfile,
  closeChatAbout,
  getInfo,
  deleteContact,
  setIsCreateContact1
}: IPropsProfile) => {
  // if (!AppContext) {
  //   // Handle the case where the context is undefined
  //   throw new Error('AppContext is not provided!');
  // }
  // const { isCreateContact, setIsCreateContact } = useContext(AppContext);
  const { isEditContact, setIsEditContact } = useContext(AppContext)!;

  const labelColor = getInfo?.label_color
    ? getInfo?.label_color.split(",")
    : [];
  const labelNames = getInfo?.label_name ? getInfo?.label_name.split(",") : [];
  useEffect(() => {
    if(getInfo?.id ){
      closeChatAbout()
      setIsCreateContact1(true)
    }else{
      return undefined
    }
  } ,[ getInfo?.id, setIsCreateContact1])
  return (
    <>
  
      {isProfile ? (
        <>
        <div
          className="ChatAbout animate__animated animate__fadeInRight"
          id="ChatAbout"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <button className="icons" onClick={closeChatAbout}>
                <span className="">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className=""
                  >
                    <path d="m19.1 17.2-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path>
                  </svg>
                </span>
              </button>
            </div>

            <div className="newText">
              <h2>Contact Info</h2>
            </div>
            <button className="icons" onClick={() => setIsEditContact(true)}>
              <span data-testid="pencil" data-icon="pencil" className="">
                <svg viewBox="0 0 24 24" width="24" height="24" className="">
                  <path
                    fill="currentColor"
                    d="M3.95 16.7v3.4h3.4l9.8-9.9-3.4-3.4-9.8 9.9zm15.8-9.1c.4-.4.4-.9 0-1.3l-2.1-2.1c-.4-.4-.9-.4-1.3 0l-1.6 1.6 3.4 3.4 1.6-1.6z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-chatAbout">
            <div className="img-about">
              <div className="img-Ani">
                {/* <img
                  src={require("../../assets/images/Avatar-7.jpeg")}
                  className="img-animated"
                  alt=""
                  draggable="false"
                /> */}
                <div className="imgBox" style={{ backgroundColor: "#CFCFCF" }}>
                  <div
                    className="text-uppercase "
                    style={{ paddingTop: "12px" }}
                  >
                    {(getInfo &&
                      getInfo.person_name &&
                      getInfo.person_name[0] + getInfo.person_name[1]) ||
                      ""}
                  </div>
                </div>
              </div>

              <div className="text-Ani">
                <h3>{getInfo?.person_name}</h3>
                <p>{getInfo?.mobile_number}</p>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Email Id</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.email_id}</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Chats 2 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Address</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.address}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Country</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.country_name}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>State</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.state_name}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>City</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.city_name}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Pin Code</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <h4>{getInfo?.pincode}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Source Type</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    <div>
                      <span
                        style={{
                          backgroundColor: getInfo?.source_name_color
                            ? getInfo.source_name_color
                            : "#eeeeee ",
                        }}
                        className="badge rounded-pill"
                      >
                        {getInfo?.source_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="titlePro">
                  <p>Labels</p>
                </div>
                <div className="bio">
                  <div className="text-inner">
                    {/* {displayNames} */}
                    {labelNames.map((name, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: labelColor[index]
                            ? labelColor[index]
                            : "#eeeeee ",
                            padding: "5px 10px",
                            borderRadius: "12px",
                            margin: "2px",
                            display: "inline-block",
                        }}
                        className="badge rounded-pill"
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Chats 2 --> */}
            {/* <div className="block">
              <div className="h-text">
                <div className="titlePro">
                  <p>Media, links and docs</p>
                  <div className="pictures-text">
                    <h4>3</h4>
                    <span className="material-symbols-outlined">
                      chevron_right
                    </span>
                  </div>
                </div>
                <div className="media-links">
                  <img
                    src={require("../../assets/images/no_image.jpeg")}
                    alt=""
                    className="avatar"
                    draggable="false"
                  />
                  <img
                    src={require("../../assets/images/no_image.jpeg")}
                    alt=""
                    className="avatar"
                    draggable="false"
                  />
                  <img
                    src={require("../../assets/images/no_image.jpeg")}
                    alt=""
                    className="avatar"
                    draggable="false"
                  />
                </div>
              </div>
            </div> */}

            {/* <!-- Chats 3 --> */}
            {/* <div className="block">
              <div className="h-text">
                <div className="head">
                  <div className="contact-star">
                    <span className="star">
                      <svg viewBox="0 0 16 14" height="20" className="">
                        <path
                          fill="currentColor"
                          d="m8.3 10.2-2.5 1.7c-.3.2-.8-.1-.7-.5L6 8.6c.1-.2 0-.4-.2-.5L3.5 6.3c-.4-.3-.2-.8.2-.8l3-.1c.2 0 .3-.1.4-.3l1-2.8c.1-.4.7-.4.8 0l1 2.8c.1.2.2.3.4.3l3 .1c.4 0 .6.5.3.8l-2.4 1.8c-.1.1-.2.3-.2.5l.9 2.9c.1.4-.3.7-.7.5l-2.5-1.7c-.1-.2-.3-.2-.4-.1z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Starred messages</span>
                  </div>
                  <div className="icon-media">
                    <span className="">
                      <svg
                        viewBox="0 0 10 21"
                        width="10"
                        height="21"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="m1 15.75 5.2-5.2L1 5.35l1.5-1.5 6.5 6.7-6.6 6.6-1.4-1.4z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="head">
                  <div className="contact-star">
                    <span className="star">
                      <svg viewBox="0 0 24 24" height="20" className="">
                        <path
                          fill="currentColor"
                          d="M12 21.7c.9 0 1.7-.8 1.7-1.7h-3.4c0 .9.8 1.7 1.7 1.7zm5.6-5.2v-4.7c0-2.7-1.8-4.8-4.3-5.4v-.6c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v.6c-2.5.6-4.3 2.7-4.3 5.4v4.7l-1.7 1.7v.9h14.6v-.9l-1.7-1.7z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Mute notification</span>
                  </div>
                </div>

                <div className="head">
                  <div className="contact-star">
                    <span className="star">
                      <svg height="20" viewBox="0 0 36 36" className="">
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18 31.5c.09 0 .182 0 .272-.003a1.5 1.5 0 0 0-.06-3c-.07.002-.14.003-.212.003v3Zm0-24c.071 0 .142 0 .213.002a1.5 1.5 0 0 0 .06-3L18 4.5v3Zm6.515-1.326a1.5 1.5 0 0 0-1.45 2.626c.124.068.246.139.367.212a1.5 1.5 0 1 0 1.554-2.566 13.503 13.503 0 0 0-.47-.272Zm5.039 4.84a1.5 1.5 0 0 0-2.566 1.554c.073.12.144.243.212.366a1.5 1.5 0 0 0 2.626-1.45 13.535 13.535 0 0 0-.272-.47Zm1.943 6.714a1.5 1.5 0 0 0-3 .06 10.76 10.76 0 0 1 0 .425 1.5 1.5 0 0 0 3 .06 13.693 13.693 0 0 0 0-.545Zm-1.67 6.787a1.5 1.5 0 0 0-2.627-1.45c-.068.124-.139.246-.212.367a1.5 1.5 0 1 0 2.566 1.554c.094-.155.185-.312.272-.47Zm-4.841 5.039a1.5 1.5 0 0 0-1.554-2.566c-.12.073-.243.144-.366.212a1.5 1.5 0 0 0 1.45 2.626c.158-.087.315-.178.47-.272ZM18 4.5C10.544 4.5 4.5 10.544 4.5 18S10.544 31.5 18 31.5v-3c-5.8 0-10.5-4.701-10.5-10.5S12.2 7.5 18 7.5v-3Z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M23.325 12.01a.865.865 0 0 1 1.21 1.21l-4.564 6.087a1.951 1.951 0 1 1-2.732-2.732l6.086-4.564Z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Disappearing messages</span>
                    <p>Off</p>
                  </div>
                  <div className="icon-media">
                    <span className="">
                      <svg
                        viewBox="0 0 10 21"
                        width="10"
                        height="21"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="m1 15.75 5.2-5.2L1 5.35l1.5-1.5 6.5 6.7-6.6 6.6-1.4-1.4z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="head">
                  <div className="contact-star">
                    <span className="star">
                      <svg viewBox="0 0 24 24" height="20" className="">
                        <path
                          fill="currentColor"
                          d="M17.3 7.6h-.9V5.8c0-2.5-1.9-4.4-4.4-4.4S7.6 3.3 7.6 5.8v1.8h-.9c-1 0-1.8.8-1.8 1.8v8.9c0 1 .8 1.8 1.8 1.8h10.6c1 0 1.8-.8 1.8-1.8V9.4c0-1-.8-1.8-1.8-1.8zM12 15.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm2.7-8.2H9.2V5.8c0-1.5 1.2-2.7 2.7-2.7s2.7 1.2 2.7 2.7v1.8h.1z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Encryption</span>
                    <p>Messagesare end-to-end encrypted. Click to verify</p>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <!-- Chats 4 --> */}
            <div className="bottom">
              {/* <!-- Text --> */}
              <div className="h-text">
                {/* <div className="Block-head">
                  <div className="contact-star">
                    <span className="star">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="M12 2.8c-5.3 0-9.7 4.3-9.7 9.7s4.3 9.7 9.7 9.7 9.7-4.3 9.7-9.7-4.4-9.7-9.7-9.7zm-7.3 9.7c0-4 3.3-7.3 7.3-7.3 1.6 0 3.1.5 4.3 1.4L6.1 16.8c-.9-1.2-1.4-2.7-1.4-4.3zm7.3 7.3c-1.6 0-3-.5-4.2-1.4L17.9 8.3c.9 1.2 1.4 2.6 1.4 4.2 0 4-3.3 7.3-7.3 7.3z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Block Shayam</span>
                  </div>
                </div> */}

                {/* <div className="Block-head">
                  <div className="contact-star">
                    <span className="star">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="M14.091 4.2H6.318c-.691 0-1.295.432-1.555 1.036l-2.591 6.132c-.086.173-.172.346-.172.605V13.7c0 .95.777 1.727 1.727 1.727h5.441L8.305 19.4v.259c0 .345.173.691.345.95l.95.864 5.7-5.7c.345-.345.518-.777.518-1.209V5.927c0-.95-.777-1.727-1.727-1.727zm3.454 0v10.364H21V4.2h-3.455z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Report Shayam</span>
                  </div>
                </div> */}

                <div className="Block-head" onClick={deleteContact}>
                  <div className="contact-star">
                    <span className="star">
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className=""
                      >
                        <path
                          fill="currentColor"
                          d="M6 18c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6H6v12zM19 3h-3.5l-1-1h-5l-1 1H5v2h14V3z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="contact-text">
                    <span className="star-text">Delete Contact</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {isEditContact && (
        <CreateContactView
          show={isEditContact}
          onHide={() => setIsEditContact(false)}
          contactData={getInfo}
          headerName={"Edit Contact"}
          setIsCreateContact1={setIsCreateContact1}
          closeChatAbout={closeChatAbout}
        />
      )}
        </>
      ) : null}
      
    </>
  );
};

export default Profile;
