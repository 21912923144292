import React, { useEffect, useRef, useState } from "react";
import { TOnChangeInput } from "../../../../../helpers/AppType";

import ConfirmationModal from "../../../../../components/model/ConfirmationModal";
import {
  createProduct,
  fetchCategoryApiForProduct,
  fetchProductApi,
  handleDeleteProduct,
  IProductView,
  updateProduct,
} from "./ProductController";
import CustomSearchDropdown from "../../../../../components/CustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../../../helpers/AppInterface";

interface IPropsProductView {
  isProductView: boolean;
  closeProductView: () => void;
}
const ProductView = ({
  isProductView,
  closeProductView,
}: IPropsProductView) => {
  const [productLists, setProductList] = useState<IProductView[]>([]);
  const [productInput, setProductInputInput] = useState("");
  const [unitInput, setUnitInput] = useState("");
  // const productRefDropdown = useRef<HTMLButtonElement>(null);
  const dropdownContactRef = useRef<Record<number, HTMLUListElement | null>>(
    {}
  );
  const [productDropdown, setProductDropdown] = useState<any>(null);
  const [hasIdAvail, setHasIdAvail] = useState<number>();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(false);
  const [editSelectedCategoryId, setEditSelectedCategoryId] = useState("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editProductId, setEditProductId] = useState<number | undefined>(
    undefined
  );
  const [rate, setRate] = useState("");
  const [gst, setGst] = useState("18");
  const [netRate, setNetRate] = useState("");

  // State for validation messages
  const [categoryError, setCategoryError] = useState("");
  const [productError, setProductError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [rateError, setRateError] = useState("");
  const [gstError, setGstError] = useState("");

  const handleRateChange = (e: { target: { value: any } }) => {
    const newRate = e.target.value;
    setRate(newRate);
    setRateError(newRate ? "" : "Rate is required");
    calculateNetRate(newRate, gst);
  };

  const handleGstChange = (e: { target: { value: any } }) => {
    const newGst = e.target.value;
    setGst(newGst);
    setGstError(newGst ? "" : "GST is required");
    calculateNetRate(rate, newGst);
  };

  const calculateNetRate = (rate: string, gst: string) => {
    const rateValue = parseFloat(rate) || 0;
    const gstValue = parseFloat(gst) || 0;
    const calculatedNetRate = rateValue + (rateValue * gstValue) / 100;
    setNetRate(calculatedNetRate.toFixed(2));
  };

  const handleCategoryChange = (selectedOption: SingleValue<IOption>) => {
    setSelectedCategory(selectedOption);
    setCategoryError(selectedOption ? "" : "Category is required");
  };
  const handelChange = (event: TOnChangeInput) => {
    // setProductInputInput(event.target.value);
    const value = event.target.value;
    setProductInputInput(value);
    setProductError(value ? "" : "Product name is required");
  };
  const handelChangeUnit = (event: TOnChangeInput) => {
    // setUnitInput(event.target.value);
    const value = event.target.value;
    setUnitInput(value);
    setUnitError(value ? "" : "Unit name is required");
  };

  const handelSubmit = () => {
    if (!selectedCategory) setCategoryError("Category is required");
    if (!productInput) setProductError("Product name is required");
    if (!unitInput) setUnitError("Unit name is required");
    if (!rate) setRateError("Rate is required");
    if (!gst) setGstError("GST is required");

    if (
      unitInput &&
      productInput &&
      selectedCategory &&
      gst &&
      rate &&
      netRate
    ) {
      if (isEditing && editProductId !== null) {
        updateProduct(
          {
            product_name: productInput,
            category_id: selectedCategory.value,
            unit: unitInput,
            rate: rate,
            net_rate: netRate,
            GST: gst,
          },
          setProductList,
          editProductId
        );
        setProductInputInput("");
        setSelectedCategory(false);
        setUnitInput("");
        setNetRate("");
        setGst("");
        setRate("");
        setIsEditing(false);
      } else {
        createProduct(
          {
            product_name: productInput,
            category_id: selectedCategory.value,
            unit: unitInput,
            rate: rate,
            net_rate: netRate,
            GST: gst,
          },
          setProductList
        );
        setProductInputInput("");
        setSelectedCategory(false);
        setUnitInput("");
        setNetRate("");
        setGst("");
        setRate("");
        setIsEditing(false);
      }
    }
  };
  const toggleDropdownProduct = (productId: number | undefined) => {
    const checkedId = productLists.find((abv) => abv.id === productId);
    setHasIdAvail(checkedId?.id);
    setProductDropdown(!productDropdown);
  };
  const handleEdit = (item: IProductView) => {
    setCategoryError("");
    setProductError("");
    setUnitError("");
    setRateError("");
    setGstError("");
    const selectedCategoryOption = categoryOptions.find(
      (option: { value: string }) => option.value === item.category_id
    );

    setProductDropdown(null);
    setProductInputInput(item.product_name);
    setUnitInput(item.unit);
    setGst(item.GST);
    setNetRate(item.net_rate);
    setRate(item.rate);
    setSelectedCategory(selectedCategoryOption);
    setEditSelectedCategoryId(item.category_id);
    setIsEditing(true);
    setEditProductId(item.id);
  };
  useEffect(() => {
    if (isProductView) {
      fetchProductApi(setProductList);
      fetchCategoryApiForProduct(setCategoryList);
    }
  }, [isProductView]);
  const handleClickOutside = (event: { target: any }) => {
    const clickedOutside = Object.values(dropdownContactRef.current).every(
      (ref) => ref && !ref.contains(event.target)
    );
    if (clickedOutside) {
      setProductDropdown(null);
    }
  };

  useEffect(() => {
    if (productDropdown !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [productDropdown]);
  const categoryOptions = categoryList.map((category: any) => ({
    value: category.id,
    label: category.category_name,
  }));
  return (
    <>
      {isProductView ? (
        <div
          className="notifications animate__animated animate__fadeInLeft"
          id="notifications"
        >
          {/* <!-- Header --> */}
          <div className="header-Chat">
            {/* <!-- Icons --> */}
            <div className="ICON">
              <div
                aria-disabled="false"
                role="button"
                className="icons"
                data-tab="2"
                title="New chat"
                aria-label="New chat"
                onClick={closeProductView}
              >
                <span data-testid="chat" data-icon="chat" className="">
                  <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path
                      fill="currentColor"
                      d="m12 4 1.4 1.4L7.8 11H20v2H7.8l5.6 5.6L12 20l-8-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div className="newText">
              <h2>Products</h2>
            </div>
          </div>
          {/* <!-- Chats --> */}
          <div className="chats-notifications">
            {/* <!-- Chats 1 --> */}
            <div className="block">
              {/* <!-- Text --> */}
              <div className="h-text">
                <div className="head" style={{ display: "block" }}>
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <h4>Select category</h4>
                  </label>
                  {/* <div className="col-12 d-flex "> */}
                  <div className="col-12">
                    <div className="">
                      <div className="add-source-of-type-section ">
                        <CustomSearchDropdown
                          options={categoryOptions}
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          className="w-100  "
                        />

                        {/* <select
                          className={`form-control pb-1 pt-1  mb-0 font-size-12 rounded-1 `}
                          onChange={handleCategoryChange}
                          value={selectedCategory}
                        >
                          <option value="">select category</option>

                          {categoryList &&
                            categoryList.map((categoryItem: any) => (
                              <>
                                <option
                                  selected={
                                    editSelectedCategoryId === categoryItem.id
                                      ? true
                                      : false
                                  }
                                  key={categoryItem.id}
                                  value={categoryItem.id}
                                >
                                  {categoryItem.category_name}
                                </option>
                              </>
                            ))}
                        </select> */}
                      </div>
                      {categoryError && (
                        <span className="text-danger">{categoryError}</span>
                      )}
                    </div>
                  </div>

                  {/* </div> */}
                  <div className="col-12 d-flex mt-1">
                    <div className="col-8 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Product Name</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          <input
                            type="text"
                            title="Product  "
                            placeholder="Add Product Name"
                            value={productInput}
                            onChange={(e) => handelChange(e)}
                          />
                        </div>
                      </div>
                      {productError && (
                        <span className="text-danger">{productError}</span>
                      )}
                    </div>

                    <div className="col-3 ">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Unit Name</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section mx-2  d-flex">
                          <input
                            type="text"
                            title="Unit"
                            placeholder="Unit Name"
                            value={unitInput}
                            onChange={(e) => handelChangeUnit(e)}
                          />
                        </div>
                      </div>
                      {unitError && (
                        <span className="text-danger">{unitError}</span>
                      )}
                    </div>
                    {/* <div className="col-1  d-flex  align-items-center mt-4">
                      <button className="" onClick={handelSubmit}>
                        <span>
                          {isEditing ? (
                            <span>
                              <svg
                                data-name="Layer 1"
                                height={24}
                                id="Layer_1"
                                viewBox="0 0 200 200"
                              >
                                <title />
                                <path
                                  fill="currentColor"
                                  d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                                />
                              </svg>
                            </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div> */}
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-4 px-1">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Rate</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          {/* <input
                            type="text"
                            title="Rate"
                            placeholder="Rate"
                            // value={productInput}
                            // onChange={(e) => handelChange(e)}
                          /> */}
                          <input
                            type="text"
                            title="Rate"
                            placeholder="Rate"
                            value={rate}
                            onChange={handleRateChange}
                          />
                        </div>
                      </div>
                      {rateError && (
                        <span className="text-danger">{rateError}</span>
                      )}
                    </div>
                    <div className="col-3 px-1">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>GST</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          {/* <input
                            type="text"
                            title="GST"
                            placeholder="GST"
                            // value={productInput}
                            // onChange={(e) => handelChange(e)}
                          /> */}
                          <input
                            type="text"
                            title="GST"
                            placeholder="GST"
                            value={gst}
                            onChange={handleGstChange}
                          />

                          <label
                            style={{
                              backgroundColor: "#f0f2f5",
                              border: "1px solid #f0f2f5",
                              padding: "5px",
                            }}
                          >
                            %
                          </label>
                        </div>
                      </div>
                      {gstError && (
                        <span className="text-danger">{gstError}</span>
                      )}
                    </div>
                    <div className="col-4 px-1">
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        <h4>Net Rate</h4>
                      </label>
                      <div className="search-bar ">
                        <div className="add-source-of-type-section ">
                          {/* <input
                            type="text"
                            title="net Rate"
                            placeholder="Net Rate"
                            // value={productInput}
                            // onChange={(e) => handelChange(e)}
                          /> */}
                          <input
                            type="text"
                            title="Net Rate"
                            placeholder="Net Rate"
                            value={netRate}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-1  d-flex  align-items-center mt-4">
                      <button className="" onClick={handelSubmit}>
                        <span>
                          {isEditing ? (
                            <span>
                              <svg
                                data-name="Layer 1"
                                height={24}
                                id="Layer_1"
                                viewBox="0 0 200 200"
                              >
                                <title />
                                <path
                                  fill="currentColor"
                                  d="M177.68,43.9c-4.5-3.5-10.5-3-14,1.5l-74,89.5-55-40c-4.5-3-10.5-2.5-14,2-3,4.5-2.5,10.5,2,14l62.5,45.5a.49.49,0,0,1,.5.5c.5,0,.5.5,1,.5s.5.5,1,.5.5,0,1,.5h6c.5,0,.5,0,1-.5.5,0,.5-.5,1-.5s.5-.5,1-.5.5-.5,1-.5a.49.49,0,0,0,.5-.5l.5-.5,80-97C182.18,53.9,181.68,47.4,177.68,43.9Z"
                                />
                              </svg>
                            </span>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="26px"
                              viewBox="0 -960 960 960"
                              width="26px"
                              fill="#5f6368"
                              // className="ml-2"
                            >
                              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                            </svg>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className=""> */}
                <div className="chats">
                  {productLists &&
                    productLists.map((item, index) => (
                      <>
                        {/* // <tr
                            //   key={index}
                            //   className=""
                            //   style={
                            //     {
                            //       // border: "1px solid white",
                            //       // borderCollapse: "collapse",
                            //       // height: "10px",
                            //     }
                            //   }
                            // >
                            //   <td className="text-start">
                            //     <span>{item.product_name}</span>
                            //   </td>
                            //   <td className="text-start">
                            //     <span>{item.unit}</span>
                            //   </td>
                            //   <td className="text-start">
                            //     <span>{item.category_name}</span>
                            //   </td>
                            //   <td className="text-start">
                            //     {item.id === -1 ? (
                            //       <span></span>
                            //     ) : (
                            //       <>
                            //         <button
                            //           className="source-of-type-list-grid-options"
                            //           id="source-of-types-options-id"
                            //           onClick={() =>
                            //             toggleDropdownProduct(item.id)
                            //           }
                            //           // ref={productRefDropdown}
                            //         >
                            //           <span>
                            //             <svg
                            //               viewBox="0 0 24 24"
                            //               width="24"
                            //               height="24"
                            //             >
                            //               <path
                            //                 fill="currentColor"
                            //                 d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                            //               ></path>
                            //             </svg>
                            //           </span>
                            //         </button>
                            //         <ul
                            //           className={`source-of-types-options ${
                            //             hasIdAvail === item.id &&
                            //             productDropdown
                            //               ? "isVisible"
                            //               : "isHidden"
                            //           } `}
                            //           id="dropLeft"
                            //           ref={(el) =>
                            //             (dropdownContactRef.current[item.id] =
                            //               el)
                            //           }
                            //         >
                            //           <li
                            //             className="listItem"
                            //             role="button"
                            //             onClick={() =>
                            //               setIsDeleteConfirmation(true)
                            //             }
                            //           >
                            //             Delete
                            //           </li>
                            //           <li
                            //             className="listItem"
                            //             role="button"
                            //             onClick={() => handleEdit(item)}
                            //           >
                            //             Edit
                            //           </li>
                            //         </ul>
                            //       </>
                            //     )}
                            //   </td>
                            // </tr> */}

                        <button
                          key={index}
                          className="block chat-list"
                          style={{ padding: "6" }}
                        >
                          <div className="h-text">
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Product Name</b> :{item.product_name}
                              </h4>
                            </div>
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Category Name </b> :
                                {item.category_name ? item.category_name : ""}
                              </h4>
                            </div>

                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Unit Name</b> :{item.unit ? item.unit : ""}
                              </h4>
                              {/* <p className="time"></p> */}
                            </div>

                            {item.id === -1 ? (
                              <span></span>
                            ) : (
                              <>
                                <button
                                  className="icon-more float-end"
                                  onClick={() => toggleDropdownProduct(item.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19 20"
                                    width="19"
                                    height="20"
                                    className="hide animate__animated animate__fadeInUp"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                                    ></path>
                                  </svg>
                                </button>

                                <ul
                                  className={`source-of-types-options ${
                                    hasIdAvail === item.id && productDropdown
                                      ? "isVisible"
                                      : "isHidden"
                                  } `}
                                  id="dropLeft"
                                  ref={(el) =>
                                    (dropdownContactRef.current[item.id] = el)
                                  }
                                >
                                  <li
                                    className="listItem"
                                    role="button"
                                    onClick={() =>
                                      setIsDeleteConfirmation(true)
                                    }
                                  >
                                    Delete
                                  </li>
                                  <li
                                    className="listItem"
                                    role="button"
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </li>
                                </ul>
                              </>
                            )}
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Rate </b> :{item.rate ? item.rate : ""}
                              </h4>
                            </div>
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>GST </b>:{item.GST ? item.GST + "%" : ""}
                              </h4>
                            </div>
                            <div
                              className="head"
                              style={{
                                paddingBottom: "2px",
                                borderBottom: "unset",
                              }}
                            >
                              <h4 className="inquiry-front">
                                <b>Net Rate </b>:
                                {item.net_rate ? item.net_rate : ""}
                              </h4>
                            </div>
                          </div>
                        </button>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {isDeleteConfirmation && (
            <ConfirmationModal
              show={isDeleteConfirmation}
              onHide={() => setIsDeleteConfirmation(false)}
              handleSubmit={() =>
                handleDeleteProduct(
                  hasIdAvail,
                  setIsDeleteConfirmation,
                  setProductList
                )
              }
              title={"Delete this Product"}
              message={"Are You Sure You Want To Delete This Product?"}
              btn1="CANCEL"
              btn2="DELETE"
            />
          )}
        </div>
      ) : null}
    </>
  );
};

export default ProductView;
