import React, { useContext, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  createContact,
  createCustomerInitialValues,
  createCustomerValidationSchema,
  updateContact,
} from "./CreateContactController";
import axiosInstance from "../../../services/axiosInstance";
import FormikCustomSearchDropdown from "../../../components/FormikCustomSearchDropdown";
import { SingleValue } from "react-select";
import { IOption } from "../../../helpers/AppInterface";
import { AppContext } from "../../../common/AppContext";

const CreateContactView = ({
  show,
  onHide,
  setContact,
  contactData,
  headerName,
  setIsCreateContact1,
  closeChatAbout
}: any) => {
  const { isEditContact ,showRightSide ,setShowRightSide} = useContext(AppContext)!;
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default behavior for Enter key
      }
    };

    if (show) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedStateId, setSelectedStateId] = useState<number>();
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [sourceOfTypesList, setSourceOfTypesList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [addOptionList, setAddOptionList] = useState();

  const handleSubmit = async (values: any) => {
    setAddOptionList(values);
    // Handle form submission logic here
    if (contactData?.id) {
      // setIsCreateContact1(false)
      updateContact(values, setContact, contactData?.id, setIsCreateContact1 ,closeChatAbout );
    } else {
      createContact(values, setContact);
    }
    onHide(); // Example: Close modal after submission using onHide prop
  };

  const handleCountriesChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (selectedOption) {
      setFieldValue("country", selectedOption.value);
      setSelectedStateId(selectedOption.value as number);
    } else {
      setFieldValue("country", "");
      setSelectedStateId(undefined);
    }
  };

  // const handleSateChange = async (event: any, setFieldValue: any) => {
  //   const { value } = event.target;
  //   if (value) {
  //     setFieldValue("state", value);
  //     setSelectedStateId(value);
  //   } else {
  //     setFieldValue("state", "");
  //     setSelectedStateId(undefined);
  //   }
  // };

  const handleSateChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (selectedOption) {
      setFieldValue("state", selectedOption.value);
      setSelectedStateId(selectedOption.value as number);
    } else {
      setFieldValue("state", "");
      setSelectedStateId(undefined);
    }
  };

  // const handleCityChange = async (event: any, setFieldValue: any) => {
  //   const { value } = event.target;
  //   if (value) {
  //     setFieldValue("city", value);
  //     setSelectedCityId(value);
  //   } else {
  //     setFieldValue("city", "");
  //     setSelectedCityId(undefined);
  //     setAreaList([]);
  //   }
  // };

  const handleCityChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (selectedOption) {
      setFieldValue("city", selectedOption.value);
      setSelectedCityId(selectedOption.value as number);
    } else {
      setFieldValue("city", "");
      setSelectedCityId(undefined);
      setAreaList([]);
    }
  };

  const handleSourceTypeChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue("source_type_id", selectedOption?.value);
  };
  // const handleSourceTypeChange = async (event: any, setFieldValue: any) => {
  //   const { value } = event.target;

  //   setFieldValue("source_type_id", value);
  // };
  // const handleAreaChange = async (event: any, setFieldValue: any) => {
  //   const { value } = event.target;
  //   // console.log("valuevalue", value);

  //   setFieldValue("area", value);
  //   // setSelectedCityId(value);
  // }
  const handleAreaChange = async (
    selectedOption: SingleValue<IOption>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (selectedOption) {
      setFieldValue("area", selectedOption.value);
    } else {
      setFieldValue("area", "");
    }
  };

  const fetchcontryapi = async () => {
    const requestData = {
      table: "a_countries",
      columns: "id,country_name,country_code",
      where: `{"isDelete": "0"}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setCountriesList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setCountriesList([]);
    }
  };

  const fetchStateapi = async () => {
    const requestData = {
      table: "a_states",
      columns: "id,state_name",
      where: `{"country_id": "${selectedCountryId || 101}"}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setStateList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setStateList([]);
    }
  };

  // console.log("selectedStateId",selectedStateId);

  const fetchCityApi = async () => {
    const requestData = {
      table: "a_cities",
      columns: "id,city_name",
      where: `{"state_id": ${selectedStateId}}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setCityList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setCityList([]);
    }
  };
  const fetchAreaApi = async () => {
    const requestData = {
      table: "a_areas",
      columns: "id,area_name",
      where: `{"city_id": ${selectedCityId}}`,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setAreaList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setAreaList([]);
    }
  };
  const fetchSourceTypeApi = async () => {
    const getUUID = await localStorage.getItem("UUID");

    const requestData = {
      table: "source_types",
      columns: "id,source_name",
      // where: `{"isDelete": "0","a_application_login_id":"${getUUID}"}`,
      where: ["isDelete=0", `a_application_login_id=${getUUID}||0`],
      request_flag: 0,
    };

    try {
      const response = await axiosInstance.post("commonGet", requestData);

      setSourceOfTypesList(response.data.data); // Assuming API response is an array of countries
    } catch (error) {
      console.error("Error fetching countries:", error);
      // Handle error (e.g., show error message, clear filtered list)
      setSourceOfTypesList([]);
    }
  };
  const updateData = async () => {
    await fetchStateapi();
    if (selectedCountryId && !selectedStateId) {
      await fetchStateapi();
      setCityList([]);
      setAreaList([]);
      setSelectedStateId(contactData?.state || undefined);
      setSelectedCityId(undefined);
    } else if (selectedStateId && !selectedCityId) {
      await fetchCityApi();
      setAreaList([]);
      setSelectedCityId(contactData?.city || undefined);
    } else if (selectedCityId) {
      await fetchAreaApi();
    } else {
      if (contactData?.country) {
        await fetchcontryapi();
        await fetchSourceTypeApi();
        setSelectedCountryId(contactData.country);
      } else {
        await fetchcontryapi();
        await fetchSourceTypeApi();
      }
    }
  };
  useEffect(() => {
    updateData();
  }, [selectedCountryId, selectedStateId, selectedCityId]);

  const stateOptions = stateList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.state_name,
  }));
  const countriesOptions = countriesList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.country_name,
  }));
  const cityOptions = cityList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.city_name,
  }));
  const areaOptions = areaList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.area_name,
  }));
  const sourcTypeOptions = sourceOfTypesList.map((itemState: any) => ({
    value: itemState.id,
    label: itemState.source_name,
  }));
  return (
    <React.Fragment>
      {show && (
        <div className="modal1 ">
          <div className="modal-content1">
            <span className="close" onClick={onHide}>
              &times;
            </span>
            <h2 className="modal-title1 form_header_text">{headerName}</h2>
            <p className="text-center " style={{ color: "#999" }}>
              {" "}
              Please enter your Contact detail.
            </p>
            <Formik
              enableReinitialize
              initialValues={createCustomerInitialValues(contactData)}
              validationSchema={createCustomerValidationSchema()}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                values,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form>
                  <div className="  mt-3    d-flex justify-content-center">
                    <div className="mb-3 py-4  ">
                      <div className="row  mx-0 px-2 gy-3  d-flex justify-content-center">
                        <div className="col-6 col-md-6 ">
                          <div className="form-group">
                            <label htmlFor="name " className="pb-2 form_label">
                              Person Name
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              name="person_name"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.person_name &&
                                touched.person_name &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="person_name"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label
                              htmlFor="country"
                              className="mb-1 form_label"
                            >
                              Country
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <FormikCustomSearchDropdown
                              name="country"
                              options={countriesOptions}
                              className={`  ${
                                errors.country &&
                                touched.country &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleCountriesChange}
                            />
                            {/* <Field
                              name="country"
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.country &&
                                      form.touched.country &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleCountriesChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select country</option>

                                    {countriesList &&
                                      countriesList.map((country: any) => (
                                        <option
                                          key={country.id}
                                          value={country.id}
                                        >
                                          {country.country_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            /> */}
                            <ErrorMessage
                              name="country"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="email_id"
                              className="pb-2 form_label"
                            >
                              Email
                            </label>
                            <Field
                              type="email_id"
                              name="email_id"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.email_id &&
                                touched.email_id &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="email_id"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label
                              htmlFor="mobile_number"
                              className="pb-2 form_label"
                            >
                              Mobile Number
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="text"
                              name="mobile_number"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.mobile_number &&
                                touched.mobile_number &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="mobile_number"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="form-group">
                            <label htmlFor="state" className="mb-1 form_label">
                              State
                              {/* <span className="text-danger">*</span> */}
                            </label>

                            {/* <Field
                              as="select"
                              name="state"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.state &&
                                touched.state &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.state &&
                                      form.touched.state &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleSateChange(e, form.setFieldValue)
                                    }
                                  >
                                    <option value="">select state</option>
                                    {stateList &&
                                      stateList.map((itemState: any) => (
                                        <option
                                          key={itemState.id}
                                          value={itemState.id}
                                        >
                                          {itemState.state_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            /> */}
                            <FormikCustomSearchDropdown
                              name="state"
                              options={stateOptions}
                              className={`  ${
                                errors.state &&
                                touched.state &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleSateChange}
                            />
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        {/* <div className="col-6 ">
                          <div className="form-group">
                            <label
                              htmlFor="district"
                              className="mb-1 form_label"
                            >
                              District
                            </label>

                            <Field
                              as="select"
                              name="district"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.district &&
                                touched.district &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.state &&
                                      form.touched.state &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleDistrictChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select district</option>
                                    {districtList &&
                                      districtList.map((itemState: any) => (
                                        <option
                                          key={itemState.id}
                                          value={itemState.id}
                                        >
                                          {itemState.district_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            ></Field>
                            <ErrorMessage
                              name="district"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div> */}
                        <div className="col-6 ">
                          <div className="form-group">
                            <label htmlFor="city" className="mb-1 form_label">
                              City
                            </label>
                            <FormikCustomSearchDropdown
                              name="city"
                              options={cityOptions}
                              className={`  ${
                                errors.city &&
                                touched.city &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleCityChange}
                            />
                            {/* <Field
                              as="select"
                              name="city"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.city &&
                                touched.city &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.city &&
                                      form.touched.city &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleCityChange(e, form.setFieldValue)
                                    }
                                  >
                                    <option value="">select city</option>
                                    {cityList &&
                                      cityList.map((itemState: any) => (
                                        <option
                                          key={itemState.id}
                                          value={itemState.id}
                                        >
                                          {itemState.city_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            ></Field> */}
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="form-group">
                            <label htmlFor="area" className="mb-1 form_label">
                              Area
                            </label>
                            <FormikCustomSearchDropdown
                              name="area"
                              options={areaOptions}
                              className={`  ${
                                errors.area &&
                                touched.area &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleAreaChange}
                            />
                            {/* <Field
                              as="select"
                              name="area"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.area &&
                                touched.area &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.area &&
                                      form.touched.area &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleAreaChange(e, form.setFieldValue)
                                    }
                                  >
                                    <option value="">select area</option>
                                    {areaList &&
                                      areaList.map((itemState: any) => (
                                        <option
                                          key={itemState.id}
                                          value={itemState.id}
                                        >
                                          {itemState.area_name}
                                        </option>
                                      ))}
                                  </select>
                                </React.Fragment>
                              )}
                            >
                             
                            </Field> */}
                            <ErrorMessage
                              name="area"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6  ">
                          <div className="form-group">
                            <label
                              htmlFor="pincode"
                              className="pb-2 form_label"
                            >
                              Pincode
                            </label>
                            <Field
                              type="text"
                              name="pincode"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.pincode &&
                                touched.pincode &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="pincode"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6  ">
                          <div className="form-group">
                            <label htmlFor="name " className="pb-2 form_label">
                              Address
                            </label>
                            <Field
                              type="text"
                              name="address"
                              className={`form-control font-size-15 rounded-1   ${
                                errors.address &&
                                touched.address &&
                                "is-invalid input-box-error"
                              }`}
                            />
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="form-group">
                            <label htmlFor="city" className="mb-1 form_label">
                              Source type
                            </label>
                            <FormikCustomSearchDropdown
                              name="source_type_id"
                              options={sourcTypeOptions}
                              className={`  ${
                                errors.source_type_id &&
                                touched.source_type_id &&
                                "is-invalid input-box-error"
                              }`}
                              onChange={handleSourceTypeChange}
                            />
                            {/* <Field
                              as="select"
                              name="source_type_id"
                              className={`form-control font-size-15 rounded-1  ${
                                errors.source_type_id &&
                                touched.source_type_id &&
                                "is-invalid input-box-error"
                              }`}
                              render={({ field, form }: any) => (
                                <React.Fragment>
                                  <select
                                    {...field}
                                    className={`form-control font-size-15 rounded-1 ${
                                      form.errors.source_type_id &&
                                      form.touched.source_type_id &&
                                      "is-invalid input-box-error"
                                    }`}
                                    onChange={(e) =>
                                      handleSourceTypeChange(
                                        e,
                                        form.setFieldValue
                                      )
                                    }
                                  >
                                    <option value="">select source type</option>
                                    {sourceOfTypesList &&
                                      sourceOfTypesList.map(
                                        (itemState: any) => (
                                          <option
                                            key={itemState.id}
                                            value={itemState.id}
                                          >
                                            {itemState.source_name}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </React.Fragment>
                              )}
                            ></Field> */}
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-12 col-12 pt-4 d-flex justify-content-center">
                          {/* px-4 me-2 py-2 */}
                          <button
                            className="border border-1 bg-danger px-4 me-2 py-2 text-light rounded-1 form_label"
                            // onClick={() => compareValues(values)}
                            onClick={onHide}
                          >
                            Close
                          </button>
                          {/* px-4 py-2 ms-2 */}
                          <button
                            type="submit"
                            className="btn btn-primary px-4 py-2 ms-2  text-light form_label rounded-1"
                            // disabled={isSubmitting}
                            style={{
                              backgroundColor: "#f58634",
                            }}
                          >
                            Save Contact
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateContactView;
