import React, { createContext, ReactNode, useState } from 'react';
import { TReactSetState } from '../helpers/AppType';
interface IAppContextType {
    isEditContact: boolean;
    setIsEditContact: TReactSetState<boolean>;
    showRightSide:boolean
    setShowRightSide:TReactSetState<boolean>
  }
  export const AppContext = createContext<IAppContextType | undefined>(undefined);

  interface IAppProviderProps {
    children: ReactNode;
  }
export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {

  const [isEditContact, setIsEditContact] = useState<boolean>(false);
  const [showRightSide, setShowRightSide] = useState(false);

  return (
    <AppContext.Provider
      value={{
        isEditContact,
        setIsEditContact,
        showRightSide,
        setShowRightSide
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
